import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, useTheme, Box, IconButton, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate } from 'react-router-dom';

const TravelTable = ({ data = [] }) => {
  
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedEventId, setSelectedEventId] = useState(null);
 

    const navigate = useNavigate();
    const handleMenuOpen = (event, eventId) => {
        setAnchorEl(event.currentTarget);
        setSelectedEventId(eventId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedEventId(null);
    };


    const handleGetEventData = (eventId) => {
        // Display toast message
        toast.info(`Modify event with ID: ${eventId}`);

        // Close menu or perform any other actions
        handleMenuClose();

        // Navigate to TravelExpUserPage with eventId as a route parameter
     
    };



    const handleModify = (eventId) => {
        toast.info(`Modify event with ID: ${eventId}`);
        handleMenuClose();
    };

    const handleComplete = (eventId) => {
        toast.info(`Complete event with ID: ${eventId}`);
        handleMenuClose();
    };


    const TableData = (data && Array.isArray(data)) ? data.map((item, index) => ({
        ...item,
        sn: index + 1,
    })) : [];

    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventName",
            label: "Event Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "task",
            label: "Task",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventEntryDate",
            label: "Apply Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "eventStatus",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Open" && "text-yellow-500"} ${value === "Close" && "text-green-500"} `}>
                        {value}
                    </p>
                ),
                filter: true,
                sort: true,
            }
        },
        {
            name: "eventId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const eventStatus = tableMeta.rowData[4]; // Assuming 'eventStatus' is at index 4
                    const isClosed = eventStatus === "Close";
                    return (
                        <div style={{ textAlign: 'start' }}>
                            <IconButton 
                                onClick={(event) => handleMenuOpen(event, value, tableMeta.rowData[1])}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && selectedEventId === value}
                                onClose={handleMenuClose}
                            >
                                {/* <MenuItem onClick={() => (navigate(/TravelExpUserPage, { state: { eventId: value } }))}>Details</MenuItem> */}

                               <MenuItem onClick={() => {
                                navigate('/TravelExpUserPage', { state: { eventId: value } });
                                handleGetEventData(value);
                                setSelectedEventId(value);
                            }}>Details</MenuItem>
                            <MenuItem onClick={() => handleModify(value)} disabled={isClosed}>Modify</MenuItem>
                            <MenuItem onClick={() => handleComplete(value)} disabled={isClosed}>Complete</MenuItem>



                            </Menu>
                        </div>
                    );
                }
            }
        }
    ];
    

   
    
    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [5, 10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };
    const theme = useTheme();

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "center", // Center align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));



    return (
        <>
             <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Travel Expense Voucher List"}
                            data={TableData}
                            columns={columns}
                            options={options}
                        />
                    
                    </ThemeProvider>
                </Box>
            </div>
        </>
    );
}

export default TravelTable;
