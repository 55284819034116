import React, { useState, useEffect } from "react";
import Sidemenu from "../component/Sidemenu";
import axios, { AxiosHeaders } from "axios";
import { toast } from "react-toastify";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { useSelector } from 'react-redux';


const Registration = () => {
  const [roles, setRoles] = useState([]);
  const [expenseSubType, setExpenseSubType] = useState([]);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([])
  const [listOfSelectedSubExpense, setListOfSelectedSubExpense] = useState([]);
  const [disabledSubExpenses, setDisabledSubExpenses] = useState([]); 


  const [tempUserId, setTempUserId] = useState(null); // State for tempUserId
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    email: "",
    mobile: "",
    role: "",
    CompanyId: "",
    profilePicAttachment: "",
    refrence_auth_temp_id: ''
  });

  const [formData1, setFormData1] = useState({

    selectedExpenseSubTypes: []

  });
  const [showForm, setShowForm] = useState(false); // State to toggle form visibility

  const userData = useSelector(state => state.auth.user);



  const fetchCompany = async () => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
      setCompany(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };
  // Fetch roles and expense types on component mount
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const rolesResponse = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_roles');
        setRoles(rolesResponse.data);

        const expenseTypesResponse = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_expense_type');
        setExpenseTypes(expenseTypesResponse.data);

        setLoading(false);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setLoading(false);
        toast.error("Failed to load initial data.");
      }
    };

    fetchInitialData();
  }, []);

  // Function to handle form visibility toggle
  const toggleForm = () => {
    setShowForm(!showForm);

    if (!showForm) {
      axios.get('https://expenseapi.sarahtech.com/api/NewUser/create_new_user')
        .then(response => {
          console.log('User temp id:', response.data);
          setTempUserId(response.data.tempUserId); 
          setFormData({...formData,refrence_auth_temp_id: response.data.tempUserId})
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          toast.error("Failed to load user data.");
        });
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'mobile') {
      if (/^\d{0,10}$/.test(value)) {
        setFormData((prevData) => ({ ...prevData, [name]: value }));
      }
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }

    // Fetch expense subtypes based on selected ExpenseType
    if (name === 'ExpenseType') {
      const expenseTypeId = value;
      const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_expense_sub_type?expenseTypeId=${expenseTypeId}`;

      axios.get(url)
        .then((response) => {
          setExpenseSubType(response.data);
        })
        .catch((err) => {
          toast.error("Failed to load expense subtypes.");
        });
    }
  };


  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;

    setListOfSelectedSubExpense((prevState) => {
      if (checked) {
        return [...prevState, value];
      }
      return prevState.filter((option) => option !== value);
    });

    setFormData1((prevState) => ({
      ...prevState,
      selectedExpenseSubTypes: checked
        ? [...prevState.selectedExpenseSubTypes, value]
        : prevState.selectedExpenseSubTypes.filter((option) => option !== value),
    }));

    setDisabledSubExpenses((prevState) => {
      if (checked) {
        return [...prevState, value];
      }
      return prevState;
    });
  };



 

  const handleSendSubExpenseTempData = async () => {
    debugger
    try {
      for (let i = 0; i < listOfSelectedSubExpense.length; i++) {
        const data = {
          tempUserId: tempUserId,
          subTypeId: listOfSelectedSubExpense[i],
        };

        const response = await axios.post('https://expenseapi.sarahtech.com/api/NewUser/employee_expense_type_auth_temp_post', data);
        console.log('handle Send Sub Expense Temp Data', response.data.statusResponse.statusCode);

        if (response.status === 200) {
          toast.success(response.data.statusResponse.status);
        }
      }



      // Clear the state after sending the data
      setListOfSelectedSubExpense([]);
      setFormData1({
        selectedExpenseSubTypes: []
      });
    } catch (error) {
      console.error('Error sending sub expense temp data:', error);
      toast.error('Failed to send sub expense temp data.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      profilePicAttachment: file,
    });

    console.log('File data uploaded', file)
  }

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault();
    const url = `https://expenseapi.sarahtech.com/api/NewUser/create_user`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      
      console.log('res', response)
      if (response.status === 200) {
        toast.success('User created successfully');
      }

      // Clear form fields after successful submission
      setFormData({
        userName: "",
        password: "",
        email: "",
        mobile: "",
        role: "",
        refrence_auth_temp_id: '', 
        CompanyId: "",
      
      });

      setShowForm(false);
    } catch (error) {
      console.error("Error creating new user:", error.response);
      if (error.response) {
        toast.error(`Failed to create new user: ${error.response.data.message}`);
      } else if (error.request) {
        console.error("No response received:", error.request);
        toast.error("Failed to create new user: No response received from the server.");
      } else {
        console.error("Error setting up request:", error.message);
        toast.error(`Failed to create new user: ${error.message}`);
      }
    }
  };

  useEffect(() => {
    fetchCompany();
  },[]);


  useEffect(() => {
    console.log(formData);
  },[formData.profilePicAttachment])

  const getHref = () => {
    if (userData.role === 'Admin') {
        return '/DashboardAdmin';
    } else if (userData.role === 'Accountant') {
        return '/DashboardAccount';
    }
    return '#'; // Default href if no role matches
};

  return (
    <div className="h-screen flex bg-[#F2FAFF]">
    <div className="p-2">
      <Sidemenu />
    </div>
    <div className="w-full flex flex-col overflow-y-auto">
      <div className="w-full h-12 flex items-center justify-start mt-12">
      <a href={getHref()} className="flex items-center space-x-2">
          <BiSolidLeftArrowAlt />
      </a>
        <p className="text-gray-800 font-poppins ml-2">New User Creation</p>
        <button
          onClick={toggleForm}
          className="ml-4 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none"
        >
          {showForm ? 'Close Form' : 'Add User'}
        </button>

        <a
href="/UserDetails" 
class="ml-4 px-4 py-2 rounded-lg bg-blue-500 text-white hover:bg-blue-600 focus:outline-none inline-block text-center"
>
Check Users
</a>
      </div>

      {/* Render the form conditionally based on showForm state */}
      {showForm && (
        <div className="flex flex-col md:flex-col bg-white mx-2 my-2">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-poppins text-gray-900">Add New user</h2>
          </div>
          <div className="flex flex-col md:flex-row justify-center">
            {/* Left section */}
            <div className="w-full md:max-w-sm max-w-64 md:mt-20 md:mx-8 mx-8 my-2">
              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="userName"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Name
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    id="userName"
                    name="userName"
                    type="text"
                    autoComplete="new-name"
                    required
                    value={formData.userName}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="email"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Email
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="new-email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="mobile"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Mobile
                  </label>
                </div>
                <div className="md:w-2/3">
                <input
        id="mobile"
        name="mobile"
        type="text"
        autoComplete="new-mobile"
        required
        value={formData.mobile}
        onChange={handleChange}
        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      />
                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="password"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Password
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>


              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Attachment"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Attachment:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="document"
                    name="profilePicAttachment"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={(e)=>{handleFileChange(e)}}

                  />


                </div>
              </div>
              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Company"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Company
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="Company"
                    name="CompanyId"
                    value={formData.CompanyId}
                    onChange={handleChange}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  >
                    <option disabled value="">Select</option>
                    {company.map((item) => (
                      <option key={item.companyId} value={item.companyId}>
                        {item.companyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="role"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Role
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="role"
                    disabled={loading}
                    name="role"
                    defaultValue={formData.role} // Use defaultValue instead of value
                    onChange={handleChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option disabled value="">Select</option>
                    {roles.map((role) => (
                      <option key={role.id} value={role.roles}>
                        {role.roles}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              {formData.role === "User" && (
                <>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="ExpenseType"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Expense Type
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="ExpenseType"
                        name="ExpenseType"
                        value={formData1.ExpenseType}
                        onChange={handleChange}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      >
                        <option value="">Select</option>
                        {expenseTypes
                          .filter(item => item.ExpenseName !== 'Travel Expense')
                          .map((item) => (
                            <option key={item.expenseId} value={item.expenseId}>
                              {item.ExpenseName}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </>
              )}
            </div>

            {/* Right section */}
            <div className="w-full md:max-w-sm max-w-64 md:mt-20 md:mx-8 mx-8 my-2">
              {formData.role === "User" && (
                <>
                  <div className="border border-gray-300 rounded-md p-4 w-full min-h-52">
                    <div className="block text-gray-700 text-sm font-poppins mb-1">Expense Sub Types</div>
                    <div className={`grid ${expenseSubType.length > 4 ? 'grid-cols-2' : 'grid-cols-1'} gap-4`}>
                      {loading ? (
                        <p>Loading options...</p>
                      ) : expenseSubType.length === 0 ? (
                        <p>No options available</p>
                      ) : (
                        expenseSubType.map((item) => (
                          <div key={item.expenseSubTypeId} className="flex items-center">
                            <input
                              type="checkbox"
                              id={item.expenseSubTypeId}
                              value={item.expenseSubTypeId}
                              checked={formData1.selectedExpenseSubTypes.includes(item.expenseSubTypeId.toString())}
                              onChange={handleCheckboxChange}
                            
                              disabled={disabledSubExpenses.includes(item.expenseSubTypeId.toString())} // Disable the checkbox if it's in the disabledSubExpenses array
                              className="mr-2"
                            />
                            <label htmlFor={item.expenseSubTypeId} className="text-gray-700">{item.expenseSubTypeName}</label>
                          </div>
                        ))
                      )}
                    </div>
                    <div className="w-full flex justify-center mt-4">
                      <button
                        onClick={handleSendSubExpenseTempData}
                        className="bg-[#007bff] text-white px-4 py-2 rounded-lg w-full"
                      >
                        ADD
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="flex justify-center items-center my-2">
            <div className="md:w-1/5">
              <button
                onClick={(e)=>{handleSubmit(e)}}
                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default Registration;
