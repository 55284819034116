// Utility function to format the date properties in the data object
const formatDatesInData = (data) => {
    if (!data || typeof data !== 'object') return null; // Handle invalid input

    // Clone the data object to avoid mutating the original object
    const formattedData = { ...data };

    // Utility function to format a date string to 'YYYY-MM-DD'
    const formatDate = (dateString) => {
        if (!dateString) return dateString;
        
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        let day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    // Format the expenseDate, toDate, and fromDate properties if they exist
    if (formattedData.expenseDate) {
        formattedData.expenseDate = formatDate(formattedData.expenseDate);
    }

    if (formattedData.toDate) {
        formattedData.toDate = formatDate(formattedData.toDate);
    }

    if (formattedData.fromDate) {
        formattedData.fromDate = formatDate(formattedData.fromDate);
    }

    if (formattedData.hotelDetails) {
        const [HotelName, Location] = formattedData.hotelDetails.split(',').map(s => s.trim());
        formattedData.HotelName = HotelName || '';
        formattedData.Location = Location || '';
    }

    return formattedData;
};

export default formatDatesInData;
