import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCurrentDate } from "../utilities/currentDateTime";
import { toast } from 'react-toastify';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, useTheme, Box, IconButton, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const TravelTableAcc = ({ data = [] }) => {
    const [showVoucher, setShowVoucher] = useState(false);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [detailedData, setDetailedData] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorUsersEl, setAnchorUsersEl] = useState(null);
    const [selectedEventId, setSelectedEventId] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [company, setCompany] = useState([]);
    const [bank, setBank] = useState([]);
    const [remark, setRemark] = useState('');
    const [openRemarkDialog, setOpenRemarkDialog] = useState(false);
    const [formData, setFormData] = useState({
        CompanyId: '',
        BankId: '',
        PaymentType: '',
        paymentDate: ''

    });

    const navigate = useNavigate();


    const handleMenuOpen = (event, eventId) => {
        setAnchorEl(event.currentTarget);
        setSelectedEventId(eventId);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedEventId(null);
    };

    const handleMenuOpenUser = (event, userId) => {
        setAnchorUsersEl(event.currentTarget);
        setSelectedUserId(userId);
    };

    const handleMenuCloseUser = () => {
        setAnchorUsersEl(null);
        setSelectedUserId(null);
    };
    const fetchCompany = async () => {
        try {
          const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
          setCompany(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };
    // const handleGetEventData = async (eventId) => {
    //     const url = `https://expenseapi.sarahtech.com/api/Events/get_event_details_by_event_id/${eventId}`;
    //     try {
    //         const response = await axios.get(url);
    //         const eventDetails = response.data.data;
    
    //         // Filter details where status is 'Accepted'
    //         const acceptedEventDetails = eventDetails.filter(detail => detail.status === 'Accepted');
    
    //         if (acceptedEventDetails.length > 0) {
    //             setDetailedData(acceptedEventDetails);
    //             setShowVoucher(true);
    //         } else {
    //             toast.error("No entries with status 'Accepted' found.");
    //             setDetailedData(null);
    //             setShowVoucher(false);
    //         }
    //     } catch (err) {
    //         toast.error("Failed to Load Expense Voucher.");
    //     }
    // };
    const handleGetEventData = (eventId) => {
        // Display toast message
        toast.info(`Modify event with ID: ${eventId}`);

        // Close menu or perform any other actions
        handleMenuClose();

        // Navigate to TravelExpUserPage with eventId as a route parameter
     
    };

    const handleData = async () => {
        const url = `https://expenseapi.sarahtech.com/api/Events/get_travel_event_details_history/${selectedUserId}/${selectedEventId}`;
        try {
            const response = await axios.get(url);
            setUserVoucherData(response.data.data); // Assuming response structure has a `data` property
            setShowVoucher(true);
        } catch (err) {
            toast.error("Failed to Load Expense Voucher.");
        }
    };

  
    const handleApproveAll = async () => {
        try {
            const data = {
                eventId: userVoucherData[0].eventId,
                userId: userVoucherData[0].userId,
                remark: remark,
                companyId: formData.CompanyId,
                bankId: formData.BankId,
                paymentType: formData.PaymentType,
                paymentDate: formData.paymentDate,
            };

            const response = await axios.put('https://expenseapi.sarahtech.com/api/Accounts/journey_all_approved_by_accountant', data);
            toast.success("All entries accepted successfully.");
            console.log('Response Handle Accept Expense Vocher:', response.data);

            setShowVoucher(false);
            setOpenRemarkDialog(false);
            setRemark('');
        } catch (error) {
            console.error('Error:', error);
            setRemark('');
        }
    };

      const handleChange = (e) => {
        console.log('handle change', e.target.value, e.target.name);
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    
     

    
          if (name === 'CompanyId') {
    
         
    
              const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
          
              axios.get(url)
                .then((response) => {
                  console.log("bank: ", response.data);
                  setBank(response.data);
                  // Uncomment the line below if you want to show a success message to the user
                  // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                  console.error('Response Error: ', err.response ? err.response.data : err.message);
                  toast.error("Failed to load expense subtypes.");
                });
          }
    
    
          if (name === 'BankId') {
            debugger
    
         
    
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;
        
            axios.get(url)
              .then((response) => {
                console.log("Pay Type: ", response.data);
                setPaymentTypes(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load expense subtypes.");
              });
        }
      };


      useEffect(() => {
         fetchCompany();
      }, []);

    const userData = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

//     const handleAcceptAll = async () => {
//    debugger
        
//         const data = {
//             eventId: userVoucherData[0].eventId,
//             userId: userVoucherData[0].userId,
//             adminRemark: remark
//         };

//         const url = `https://expenseapi.sarahtech.com/api/Admin/journey_all_approved_by_admin`;
//         try {
//             await axios.post(url, data);
//             toast.success("All entries accepted successfully.");
//             setShowVoucher(false);
//             setOpenRemarkDialog(false);
//         } catch (err) {
//             toast.error("Failed to accept all entries.");
//         }
//     };

// const handleRejectAll = async () => {



//     try {
      
//     const data = {
//         eventId: userVoucherData[0].eventId,
//         userId: userVoucherData[0].userId,
//         adminRemark: remark
//     };

//       const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/journey_all_rejected_by_admin', data);
//       toast.success("All entries accepted successfully.");
//       setShowVoucher(false);
//       setOpenRemarkDialog(false);

//       console.log('Response Handle Accept Expense Vocher:', response.data);
    
//       setRemark('');

//     } catch (error) {

//       console.error('Error:', error);
//       setRemark('');
//     }
//   }

    // const handleRejectAll = async () => {
    //     const url = `https://expenseapi.sarahtech.com/api/Events/reject_all/${selectedUserId}/${selectedEventId}`;
    //     try {
    //         await axios.post(url);
    //         toast.success("All entries rejected successfully.");
    //         setShowVoucher(false); // Hide the voucher table after action
    //     } catch (err) {
    //         toast.error("Failed to reject all entries.");
    //     }
    // };

    const TableData = (data && Array.isArray(data)) ? data.map((item, index) => ({
        ...item,
        sn: index + 1,
    })) : [];

    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventName",
            label: "Event Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "task",
            label: "Task",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "eventEntryDate",
            label: "Apply Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
      
        {
            name: "eventId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => {
                    const eventStatus = tableMeta.rowData[4]; // Assuming 'eventStatus' is at index 4
                    const isClosed = eventStatus === "Close";
                    return (
                        <div style={{ textAlign: 'start' }}>
                            <IconButton 
                                onClick={(event) => handleMenuOpen(event, value, tableMeta.rowData[1])}
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl) && selectedEventId === value}
                                onClose={handleMenuClose}
                            >
                                {/* <MenuItem onClick={() => (navigate(/TravelExpUserPage, { state: { eventId: value } }))}>Details</MenuItem> */}

                               <MenuItem onClick={() => {
                                navigate('/TravelExpUserPageaccountant', { state: { eventId: value } });
                                handleGetEventData(value);
                                setSelectedEventId(value);
                            }}>Details</MenuItem>
                          



                            </Menu>
                        </div>
                    );
                }
            }
        }
    ];
    

    const detailedColumns = [
        {
            name: "userName",
            label: "User Name",
        },
        {
            name: "userId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => (
                    <div style={{ textAlign: 'start' }}>
                        <IconButton onClick={(event) => handleMenuOpenUser(event, value, tableMeta.rowData[1])}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu
                            anchorEl={anchorUsersEl}
                            keepMounted
                            open={Boolean(anchorUsersEl) && selectedUserId === value}
                            onClose={handleMenuCloseUser}
                        >
                            <MenuItem onClick={() => handleData()}>Details</MenuItem>
                        </Menu>
                    </div>
                )
            }
        }
    ];

    const detailedUserColumns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => <div style={{ textAlign: 'start' }}>{tableMeta.rowIndex + 1}</div>
            }
        },
        {
            name: "expenseSubType",
            label: "Expense",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value} ₹</div>
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "eventEntryDate",
            label: "Apply Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Accepted" && "text-yellow-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"}`}>
                        {value}
                    </p>
                ),
                filter: true,
                sort: true,
            }
        }
    ];
    
    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [5, 10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };
    const theme = useTheme();

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleRemarkDialogOpen = () => {
        setOpenRemarkDialog(true);
    };

    const handleRemarkDialogClose = () => {
        setOpenRemarkDialog(false);
        setRemark('');
    };

    return (
        <>
             <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Travel Expense Voucher List"}
                            data={detailedData || TableData}
                            columns={detailedData ? detailedColumns : columns}
                            options={options}
                        />
                        {showVoucher && (
                            <>
                                <div>
                                    <MUIDataTable
                                        title={"User Voucher Details"}
                                        data={userVoucherData}
                                        columns={detailedUserColumns}
                                        options={options}
                                    />
                                    <Box display="flex" justifyContent="start" mt={2}>
                                        <Button variant="contained" color="primary" onClick={handleRemarkDialogOpen}>Action</Button>
                                    </Box>
                                </div>

                                {/* Remark Dialog */}
                                <Dialog open={openRemarkDialog} onClose={handleRemarkDialogClose}>
                <DialogTitle>Admin Remark</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Remark"
                        fullWidth
                        value={remark}
                        onChange={(e) => setRemark(e.target.value)}
                    />
                    <TextField
                        select
                        fullWidth
                        label="Select Company"
                        name="CompanyId"
                        value={formData.CompanyId}
                        onChange={handleChange}
                    >
                        {company.map((c) => (
                            <MenuItem key={c.companyId} value={c.companyId}>
                                {c.companyName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Select Bank"
                        name="BankId"
                        value={formData.BankId}
                        onChange={handleChange}
                        disabled={!formData.CompanyId}
                    >
                        {bank.map((b) => (
                            <MenuItem key={b.bankId} value={b.bankId}>
                                {b.bankName}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        fullWidth
                        label="Select Payment Type"
                        name="PaymentType"
                        value={formData.PaymentType}
                        onChange={handleChange}
                        disabled={!formData.BankId}
                    >
                        {paymentTypes.map((pt) => (
                            <MenuItem key={pt.paymentMasterId} value={pt.paymentType}>
                                {pt.paymentType}
                            </MenuItem>
                        ))}
                    </TextField>

                 <div className="md:flex md:items-center mb-3 2xl:mb-6">
  <div className="md:w-1/3">
    <label
      htmlFor="Payment Date"
      className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
      Payment Date:
    </label>
  </div>

  <div className="md:w-1/3">
    <input
      type="date"
      placeholder="Payment Date"
      name="paymentDate"
      value={formData.paymentDate}
      onChange={handleChange}
      required
      max={getCurrentDate()}
      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
    />
  </div>
</div>
                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemarkDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleApproveAll} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
                            </>
                        )}
                    </ThemeProvider>
                </Box>
            </div>
        </>
    );
}

export default TravelTableAcc;
