import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import ShowMoreText from '../utilities/ShowMoreText';
import formatDate from '../utilities/formatDate';
import getFileNameAndType from '../utilities/getFileNameAndType';
import pdfIcon from '../Images/PDF_file_icon.svg.png';
import imgIcon from '../Images/7156838.png';
import getFileType from '../utilities/getFileType';
import {
    TERipple,
    TEModal,
    TEModalDialog,
    TEModalContent,
    TEModalHeader,
    TEModalBody,
    TEModalFooter
} from "tw-elements-react";
import DateTime from 'datatables.net-datetime';

const AdminFormDetailsPopup = ({ formData, showModal, setShowModal, handleSendData }) => {
    // Redux state
    const userData = useSelector(state => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    // Destructure formData
    const {
        AccountantActionDate,
        AccountantRemark,
        AdminActionDate,
        AdminRemark,
        ApplyDate,
        Attachment,
        Description,
        DistanceTravelled,
        DonationFor,
        DonationType,
        EntryBy,
        ExpenseAmount,
        ExpenseDate,
        ExpenseType,
        FromDate,
        FromSource,
        Role,
        Status,
        SubType,
        HotelName,
        ToDate,
        ToSource,
        ExpenseName,
        UserId,
        VehicleType,
        UserName
    } = formData;

    const openAttachment = (file) => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
    };
    
   
    const OpenAttachmentInNewWindow = () =>{
        openAttachment(Attachment);
    }

    // Determine file type
    const fileType = getFileType(Attachment);

    return (
        <TEModal show={showModal} setShow={setShowModal}>
            <TEModalDialog size="xl">
                <TEModalContent>
                    <TEModalHeader>
                        <h5 className="text-xl font-medium leading-normal text-neutral-800 dark:text-neutral-200">
                            Voucher Details
                        </h5>
                        <button
                            type="button"
                            className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none"
                            onClick={() => setShowModal(false)}
                            aria-label="Close"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="h-6 w-6"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </TEModalHeader>

                    {/* Modal body */}
                    <TEModalBody>
                        <TEModalBody >
                            <div className="border border-black p-3">
                                {/* First set of information */}
                                <div className="flex px-8">
                                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                        <p className="font-bold"> <span className='font-normal px-4 py-2 bg-blue-400 rounded-full text-white'>   {ExpenseType} </span></p>
                                    </div>
                                    <div className="w-full sm:w-1/2 lg:w-1/3  py-1">

                                    </div>
                                    <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1"></div>
                                </div>

                                <div className="flex">
                                    <div className="flex-grow  p-4 dark" style={{ flexGrow: 3 }}>
                                        <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                            {/* Second set of information */}
                                            <div className="flex w-full">
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Expense Type :<span className='font-normal'> {SubType}</span> </p>
                                                </div>
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Expense Amount: <span className='font-normal'> {ExpenseAmount}&nbsp;₹  </span></p>
                                                </div>
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Expense Date: <span className='font-normal'> {formatDate(ExpenseDate)}</span></p>
                                                </div>
                                            </div>

                                            

                                        </div>
                                        <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                            {/* Second set of information */}
                                            <div className="flex w-full">
                                                {/* <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Apply Date :<span className='font-normal'> {formatDate(Date())}</span> </p>
                                                </div> */}
                                                {/* <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                    <p className="font-bold">Status: <span className={`font-normal capitalize px-3 py-1 inline-block rounded-full cursor-pointer text-white  text-center bg-orange-500 `}> {Status} </span></p>
                                                </div> */}
                                                <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">

                                                </div>
                                            </div>

                                        </div>


                                        <div className="w-full lg:w-3/4 px-4 py-2 flex items-center">
                                            {/* Third set of information */}
                                            <div className="flex w-full px-4 py-1">

                                                <ShowMoreText text={Description} maxWords={10} />


                                            </div>

                                        </div>

                                    </div>
                                    <div className="flex-grow p-4 flex items-center">
                                        {fileType === 'pdf' ? (
                                            <img src={pdfIcon} alt="PDF Icon" className="mr-4 w-12 h-12" />
                                        ) : (
                                            <img src={imgIcon} alt="Image" className="mr-4 w-12 h-12" />
                                        )}
                                        <span onClick={()=>{OpenAttachmentInNewWindow()}} >{`${'Attachment'}.${fileType}`}</span>
                                    </div>
                                </div>


                                {SubType === 'FUEL EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow  lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        {/* Second set of information */}
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From Place: <span className="font-normal">{FromSource} </span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To Place : <span className="font-normal">{ToSource}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Distance Travelled : <span className="font-normal">{DistanceTravelled} Km</span></p>
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="flex-growlg:flex-grow-0 lg:w-1/4 px-4 py-2 flex items-center">
                                        {/* You can add content here if needed */}
                                    </div>
                                </div>
                                }





{SubType === 'MISCELLANEOUS EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow  lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        {/* Second set of information */}
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Expense Name: <span className="font-normal">{ExpenseName} </span></p>
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="flex-growlg:flex-grow-0 lg:w-1/4 px-4 py-2 flex items-center">
                                        {/* You can add content here if needed */}
                                    </div>
                                </div>
                                }
                                {SubType === 'HOTEL EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow  lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        {/* Second set of information */}
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">Hotel Name: <span className="font-normal">{HotelName} </span></p>
                                            </div>
                                           
                                        </div>
                                    </div>

                                    <div className="flex-growlg:flex-grow-0 lg:w-1/4 px-4 py-2 flex items-center">
                                        {/* You can add content here if needed */}
                                    </div>
                                </div>
                                }


                                {SubType === 'TRAVEL EXPENSE' && <div className="flex p-4">
                                    <div className="flex-grow  lg:flex-grow-0 lg:w-3/4 px-4 py-2 flex items-center">
                                        {/* Second set of information */}
                                        <div className="flex w-full">
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">From Date : <span className="font-normal">{formatDate(FromDate)} </span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">
                                                <p className="font-bold">To Date : <span className="font-normal">{formatDate(ToDate)}</span></p>
                                            </div>
                                            <div className="w-full sm:w-1/2 lg:w-1/3 px-4 py-1">

                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex-growlg:flex-grow-0 lg:w-1/4 px-4 py-2 flex items-center">
                                        {/* You can add content here if needed */}
                                    </div>
                                </div>
                                }
                            </div>
                        </TEModalBody>
                    </TEModalBody>

                    {/* Modal footer */}
                    <TEModalFooter>
                        <TERipple rippleColor="light">
                            <button
                                type="button"
                                className="inline-block rounded bg-primary-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out hover:bg-primary-accent-100 focus:bg-primary-accent-100 focus:outline-none focus:ring-0 active:bg-primary-accent-200"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                        </TERipple>
                        <TERipple rippleColor="light">
                            <button
                                onClick={() => { handleSendData(); setShowModal(false); }}
                                type="button"
                                className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                            >
                                Send Details
                            </button>
                        </TERipple>
                    </TEModalFooter>
                </TEModalContent>
            </TEModalDialog>
        </TEModal>
    );
}

export default AdminFormDetailsPopup;
