import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import applied from "../Images/applied.png";
import Accepted from "../Images/Accepted.png";
import rejected from "../Images/rejected.png";
import pending from "../Images/pending.png";
import Header from "../component/Header";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import Sidemenu from "../component/Sidemenu";
import axios from "axios";
import Table from "../component/Table";
import VoucherPopup from "../component/VoucherPopup";






const ExpenseRequestAccountant = () => {
   
    const [userPendingRequestVoucher, setUserPendingRequestVoucher] = useState([]);
    const [refreshList, setRefresheList] = useState(0)

    const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  

  


  

    const getAcceptedVoucherListData = async () => {
        try {
            const url = 'https://expenseapi.sarahtech.com/api/Accounts/get_user_voucher_details_by_status/Accepted';
            const response = await axios.get(url);
            console.log('Users Pending Request', response.data);
            setUserPendingRequestVoucher(response.data);
            setRefresheList(refreshList + 1);

        } catch (error) {
            console.error('Error fetching voucher data:', error.message);
        }
    };


    useEffect(() => {
        const handleGetExpenseAnalysis = async () => {
          
            const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis`;

            try {
                const response = await axios.get(url);
                setUserVoucherAnalyticsData(response.data);
                console.log('User Analysis Data', response.data);
            } catch (err) {
                console.log('User Analysis Response Error', err.response.data);
            }
        };

        handleGetExpenseAnalysis();

    }, []);


    useEffect(() => {
        getAcceptedVoucherListData();

    }, []);

 

    return (
        <div className="h-screen flex bg-[#F2FAFF]">
        <div className="p-2">
          <Sidemenu />
        </div>
        <div className="w-full flex flex-col overflow-y-auto">
          <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/DashboardAccount" className="flex items-center space-x-2">
                          <BiSolidLeftArrowAlt />
                      </a>
                      <p className="text-gray-800 font-poppins ml-2">Expense Request</p>
          </div>
  
  
            
  
  
                  {/* Table */}
                  <Table data={userPendingRequestVoucher} />
  
              </div>
          </div>
    );
}

export default ExpenseRequestAccountant;