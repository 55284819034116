import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import UserAccountTable from "../component/UserAccountTable";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';






const UserDetails = () => {


  const [userAccountsDetails, setUserAccountsDetails] = useState([]);
  const [refreshList, setRefresheList] = useState(0)


  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);






const FetchUserAccountsDetails = async () => {

const url = 'https://expenseapi.sarahtech.com/api/Accounts/get_users_list';

try{
    const response = await axios.get(url);
    const data = response.data;
    setUserAccountsDetails(data);
    console.log('User Account Data', data);
 } catch (err) {
        console.log('User Account Data Retrieve Error', err.response.data);
    }
}











  useEffect(() => {
    FetchUserAccountsDetails();
  }, []
);

















  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/Registration" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <h6>Register User</h6>
   
        </div>

        <div className="flex flex-col bg-white mx-2 my-2">
        <div className="divide-gray-200 mx-2 w-full">
        <UserAccountTable data={userAccountsDetails} />





      </div>
      </div>
      </div>
    </div>

  )
}

export default UserDetails