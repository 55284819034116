
import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import TravelTempTable from "../component/TravelTempTable";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";






const TravelVoucherFill = () => {
    

  const [showModal, setShowModal] = useState(false);
  const [isFood, setIsFood] = useState(false)
  const [isFuel, setIsFuel] = useState(false)
  const [isTravel, setIsTravel] = useState(false)
  const [isHotel, setIsHotel] = useState(false)
  const [isParking, setIsParking] = useState(false)
  const [expenseSubType, setExpenseSubTypeFor] = useState([]);
  const [vehicle, setVehicle] = useState(false)
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState(false)
  const [isMisc, setIsMisc] = useState(false)
  const [authExpenseList, setAuthExpenseList] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [refreshList, setRefresheList] = useState(0)
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [userTempVoucherData, setUserTempVoucherData] = useState([]);
  const [eventIdVoucher, setEventIdVoucher] = useState(0);


  const defaultValue = 0;



  const [formData, setFormData] = useState({
    ExpenseType: '',
    SubType: '',
    ExpenseDate: '',
    ExpenseAmount: '',
    Attachment: null,
    attachmentPath: "",
    HotelName: '',
    Location: '',
    vehicleType:'',
    Description: '',
    FromPlace: '',
    toPlace: '',
    Distance: '',
    FromDate: '',
    ToDate: '',
    ApplyDate: '0000-00-00 00:00:00.000',
    noOfPerson: '',
    UserName: '',
    Status: 'Pending',
    AdminActionDate: '',
    AdminRemark: '',
    AccountantActionDate: '',
    AccountantRemark: '',
    UserId: null,
    FilledByUserId: null,
    Role: '',
    EntryBy: '',
    DonationFor: '',
    DonationType: '',
    ExpenseName:'',

    eventId: null
  });

  const navigate = useNavigate();

  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


  console.log(actionId);
  useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);


  const fetchVehicle = async () => {
    try {
      const response = await axios.get('https://localhost:7164/api/MasterApi/get_vehicle_mater');
      setVehicle(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching companies:', error);
      setLoading(false);
    }
  };
  useEffect(() => {

    fetchVehicle();
  }, []);


  const fetchUserTempVoucherData = async () => {
    const eventId = actionId;
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Events/get_journey_expense_voucher_temp/${eventId}/${userId}`;

    try {
        const response = await axios.get(url);
        const data = response.data.data; // Adjust based on your actual API response
        setUserTempVoucherData(data);
        console.log('User Expense Data temp voucher', data);
    } catch (err) {
        console.log('User Expense Data Error', err.response.data);
    }
};


//   const fetchData = async () => {
//     try {
//       const userId = userData.userId;
//       const response = await axios.get(`https://expenseapi.sarahtech.com/api/NewUser/get_expense_types_by_userid/${userId}`);
//       if (response.data) {
//         const expenseTypeIds = new Set();
//         const uniqueExpenseTypes = [];

//         response.data.expenseTypesList.forEach(item => {
//           if (!expenseTypeIds.has(item.expenseTypeId)) {
//             uniqueExpenseTypes.push({
//               expenseTypeId: item.expenseTypeId,
//               expenseTypeName: item.expenseTypeName
//             });
//             expenseTypeIds.add(item.expenseTypeId);
//           }
//         });

//         setExpenseTypes(uniqueExpenseTypes);
//         setAuthExpenseList(response.data.expenseTypesList);
//       } else {
//         console.error('Data received is not an array:', response.data);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     }
//   };







  //   const fetchExpenseSubType = () => {
  //     const url = `http://172.10.10.61:94/api/Master/get_expense_sub_type?expenseTypeId=${1}`;
  //     axios.get(url)
  //       .then((response) => {
  //         console.log('Expense SubType Data', response.data);
  //         setExpenseSubTypeFor(response.data);
  //       })
  //       .catch((err) => {
  //         console.log('Response Error', err.response.data);
  //         toast.error("Failed to load expense subtypes.");
  //       });
  //   }


  const fetchEventData = async () => {
    const eventId = actionId;
    const url = `https://expenseapi.sarahtech.com/api/Events/get_event_details_by_event_id/${eventId}`;
    try {
      const response = await axios.get(url);
      console.log('evntid data', response);
  
     
  
      // Ensure data is an array and get the first event
      const data = response.data.data;
      const firstEvent = data.length > 0 ? data[0] : null;
  
      if (firstEvent) {
        // Extract and format the required fields
        const eventName = firstEvent.eventName || 'No Name provided';
        const task = firstEvent.task || 'No task provided';
        const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
        const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;
        const fromDate = firstEvent.fromDate ? firstEvent.fromDate.split("T")[0] : null;
        const toDate = firstEvent.toDate ? firstEvent.toDate.split("T")[0] : null;
  
        const trimmedData = {
          task,
          entryDate,
          eventName,
          expenseTypeId,
          fromDate,
          toDate
        };
  
        console.log('Event Data:', trimmedData);
        setData(trimmedData);
        return trimmedData;
      } else {
        console.log('No event data found');
        return null;
      }
    } catch (err) {
      toast.error("Failed to Load Expense Voucher.");
    }
  };
  
  const fetchData = async () => {
    try {
        const response = await fetch('https://expenseapi.sarahtech.com/api/MasterApi/get_expense_type');
        if (response.ok) {
            const jsonData = await response.json();
            console.log("jsonData:", jsonData);
            // Filter to get only 'TRAVEL EXPENSE' (expenseId: 2)
            const filteredData = jsonData.filter(item => item.expenseId === 2);
            setExpenseTypes(filteredData);
        } else {
            console.error('Failed to fetch data:', response.status);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  
const handleGetSubExpenseAlongwithExpenseType = async () => {
  try {
    const response = await axios.get('https://expenseapi.sarahtech.com/api/Accounts/get_all_expense_sub_type_along_with_expense_type');
    console.log('response of expense type along with sub expense type', response.data);

    // Filter response data where expense type id is 2
    const filteredData = response.data.listofExpenseAndSubExpense.filter(item => item.expenseId === 2);

    setExpenseSubTypeFor(filteredData);
  } catch (error) {
    console.log('Error in handleGetSubExpenseAlongwithExpenseType:', error);
  }
}


useEffect(() => {
  handleGetSubExpenseAlongwithExpenseType();
}, []);



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData({
      ...formData,
      AttachmentFile: file,
    });
    console.log('File data uploaded', file)
  }


  const handleSubmit = async (e) => {
    debugger
    setFormData({
      ...formData,
      UserName: userData.userName,
      UserId: userData.userId,
      FilledByUserId: userData.userId,
      Role: userData.role,
      eventId: eventIdVoucher
    });
    console.log('User Data', userData);

    e.preventDefault();
    setShowModal(true)


  };

  useEffect(() => {
    console.log('form data changed: ', formData);
  }, [formData]);




  const handleSendData = async () => {


    try {

      console.log('Sending User Data', formData)
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
      const hotelDetails = `${formData.HotelName} , ${formData.Location}`;


      const response = await axios.post(
        'https://expenseapi.sarahtech.com/api/Events/add_journey_expense_voucher_temp',
        {
          ...formData,
          ApplyDate: formattedDate,
          HotelDetails: hotelDetails,

        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      toast.success(response.data.status);
      setFormData({
        ExpenseType: '',
        SubType: '',
        ExpenseDate: '',
        ExpenseAmount: '',
        Attachment: null,
        Description: '',
        FromPlace: '',
        toPlace: '',
        Distance: '',
        FromDate: '',
        ToDate: '',
        ApplyDate: '0000-00-00 00:00:00.000',
        UserName: '',
        Status: 'Pending',
        AdminActionDate: '',
        AdminRemark: '',
        AccountantActionDate: '',
        AccountantRemark: '',
        UserId: null,
        noOfPerson:'',
        vehicleType:'',
        FilledByUserId: null,
        HotelName: '',
        Location: '',
        Role: '',
        EntryBy: '',
        DonationFor: '',
        DonationType: '',
        eventId: null


      });
      document.getElementById('document').value = '';
      setRefresheList(refreshList + 1);
    } catch (error) {
      console.error('Error:', error);
      if (error.response) {

        toast.error(error.response.data);
      } else if (error.request) {

        toast.error('No response received from the server');
      } else {

        toast.error('An error occurred while processing your request');
      }
    }
  }

  
  const FinalSubmit = async () => {
    try {
        console.log('Sending User final Data', formData);
     

        // Simplified payload with only userId and eventId
        const payload = {
          
            userId: userData.userId, // Assuming formData contains UserId
            eventId: formData.eventId // Assuming formData contains eventId
        };

        const response = await axios.post(
            'https://expenseapi.sarahtech.com/api/Events/final_submit_journey_expense',
            payload,
            {
                headers: {
                    'Content-Type': 'application/json', // Changed to JSON as it's a simple payload
                },
            }
        );
        console.log('Response final submit:', response.data);
        toast.success(response.data.status);
        if (userData.role === 'User') {
          navigate('/Dashboard');
        }  else if (userData.role === 'Accountant') {
          navigate('/DashboardAccount');
        }


        // Reset formData if necessary
        setFormData({
          ExpenseType: '',
          SubType: '',
          ExpenseDate: '',
          ExpenseAmount: '',
          Attachment: null,
          Description: '',
          FromPlace: '',
          ToPlace: '',
          Distance: '',
          FromDate: '',
          ToDate: '',
          ApplyDate: '0000-00-00 00:00:00.000',
          UserName: '',
          Status: 'Pending',
          AdminActionDate: '',
          AdminRemark: '',
          AccountantActionDate: '',
          AccountantRemark: '',
          UserId: null,
          FilledByUserId: null,
          Role: '',
          EntryBy: '',
          DonationFor: '',
          DonationType: '',
          eventId: null
      });
        document.getElementById('document').value = '';
    } catch (error) {
        console.error('Error:', error);
        if (error.response) {
            toast.error(error.response.data);
        } else if (error.request) {
            toast.error('No response received from the server');
        } else {
            toast.error('An error occurred while processing your request');
        }
    }
};



  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,
      eventId: eventIdVoucher
    }));

    console.log('User Voucher Fill EventIdVoucher', eventIdVoucher)
  }, [eventIdVoucher, refreshList]);


  const handleChange = (e) => {
    debugger
    debugger
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));


    

    



  if (name === 'SubType' && value === 'FOOD EXPENSE') {
    setIsFuel(false);
    setIsTravel(false);
    setIsFood(true);
    setIsMisc(false);
    setIsHotel(false);

    console.log('set is FOOD', isFood);
  }

  if (name === 'SubType' && value === 'HOTEL EXPENSE') {
    setIsFuel(false);
    setIsMisc(false);
    setIsFood(true);
    setIsHotel(true);
  }

if (name === 'SubType' && value === 'FUEL EXPENSE') {
    setIsFuel(true);
    setIsHotel(false);
    setIsMisc(false);
    setIsFood(false);

}

if (name === 'SubType' && value === 'PARKING') {
    setIsHotel(false);
    setIsFood(false);
  setIsFuel(false);
  setIsMisc(false);

}

if (name === 'SubType' && value === 'TOLL') {
    setIsHotel(false);
    setIsFood(false);
  setIsFuel(false);
  setIsMisc(false);

  console.log('set is Travel', isTravel);
}

if (name === 'SubType' && value === 'MISCELLANEOUS EXPENSE') {
    setIsHotel(false);
    setIsFood(false);
  setIsFuel(false);
    setIsMisc(true);
    console.log('set is Travel', isTravel);
  }
}



  useEffect(() => {
  }, [isFuel]);

  useEffect(() => {
    console.log('set is Travel', isTravel);
  }, [isTravel]);


  useEffect(() => {
    fetchData();
     fetchUserTempVoucherData();
  }, [refreshList]);





  useEffect(() => {
    console.log('store data of User', userData);
    console.log('store data of User', isLoggedIn);
    fetchEventData();
  }, [refreshList]);


  useEffect(() => {
    console.log('Data:', data);
  }, [data]);







  const getHref = () => {
    if (userData.role === 'User') {
        return '/Dashboard';
    } else if (userData.role === 'Accountant') {
        return '/DashboardAccount';
    }
    return '#'; // Default href if no role matches
};




  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
        <a href={getHref()} className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2">{data.eventName
            ? data.eventName
            : "No task provided"}</p>
        </div>
        <div className="flex flex-row bg-white mx-2 py-4">
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            {/* Content on the left side */}
            <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">From Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.fromDate ? data.fromDate : "No date provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">To Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.toDate ? data.toDate : "No date provided"}</p>
          </div>
        </div>



        {/* form */}
        <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center h-screen">
          <div className="flex flex-col md:flex-row 2xl:gap-36 bg-white mx-2 my-2">
            {/* Left section */}
            <div className="w-full md:max-w-sm max-w-64 2xl:gap-8 md:mt-20 md:mx-8 mx-8 my-2">
            <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Sub Type"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Type:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <select
                    id="SubType"
                    name="SubType"
                    value={formData.SubType}
                    onChange={(e) => {
                      const selectedSubType = e.target.value;
                      const selectedSubTypeData = expenseSubType.find(item => item.expenseSubType === selectedSubType);
                      const selectedExpenseType = selectedSubTypeData ? selectedSubTypeData.expenseType : '';

                      handleChange(e); // Call your handleChange function with the event
                      setFormData({
                        ...formData,
                        SubType: selectedSubType, // Update SubType in formData
                        ExpenseType: selectedExpenseType // Update ExpenseType in formData
                      });
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  >
                    <option disabled value="">
                      Select
                    </option>
                    {expenseSubType.map((item) => (
                      <option key={item.expenseSubTypeId} value={item.expenseSubType}>
                        {item.expenseSubType} - {item.expenseType}
                      </option>
                    ))}
                  </select>

                </div>
              </div>


              <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Amount"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Amount:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="tel"
                    placeholder="Expense Amount"
                    name="ExpenseAmount"
                    value={formData.ExpenseAmount}
                    onChange={handleChange}
                    required
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  />
                </div>
              
              </div>
              {isHotel && (
                <>


                <div className="md:flex md:items-center mb-3">
                  <div className="md:w-1/3">
                    <label
                      htmlFor="Hotel Name"
                      className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                      Hotel Name:
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="text"
                      placeholder="Hotel Name"
                      name="HotelName"
                      value={formData.HotelName}
                      onChange={handleChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                    />
                  </div>
                </div>

                <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                  <div className="md:w-1/3">
                    <label
                      htmlFor="From Date"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                    >
                      From Date:
                    </label>
                  </div>
                  <div className="md:w-2/3">
                    <input
                      type="date"
                      name="fromDate"
                      value={formData.fromDate}
                      onChange={handleChange}
                      required
                      max={getCurrentDate()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                    />
                  </div>
                </div>

             

                </>

              )}
      
      {isFuel && (
                                <>

<div className="md:flex md:items-center mb-3 2xl:mb-6 ">
<div className="md:w-1/3">
    <label
        htmlFor=" From Source"
        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
    >
        From Source:
    </label>
</div>
<div className="md:w-2/3">
    <input
        type="text"
        placeholder="From Place"
        name="fromSource"
        value={formData.fromSource}
        onChange={handleChange}
        required
        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
    />
</div>
</div>
{/* 
                                    <div className="md:flex md:items-center mb-3">
                                        <div className="md:w-1/3">
                                            <label
                                                htmlFor="Vehicle Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Vehicle Type:
                                            </label>
                                        </div>
                                        <div className="md:w-2/3">
                                            <input
                                                type="text"
                                                placeholder="Vehicle Type"
                                                name="vehicleType"
                                                value={formData.vehicleType}
                                                onChange={handleChange}
                                                className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                                            />
                                        </div>
                                    </div> */}
<div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                    <label
                                                htmlFor="Vehicle Type"
                                                className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                                            >
                                                Vehicle Type:
                                            </label>
                    </div>
                    <div className="md:w-2/3">
                      <select
                        id="vehicleType"
                        name="vehicleType"
                        value={formData.vehicleType}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      >
                        <option disabled value="">Select</option>
                        {vehicle.map((item) => (
                          <option key={item.vehicleId} value={item.vehicleType}>
                            {item.vehicleType}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                                </>

                            )}
              <div className="md:flex md:items-center mb-6">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Description"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Description:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="text"
                    placeholder="Description..."
                    name="Description"
                    value={formData.Description}
                    onChange={handleChange}
                    className="border border-gray-300 rounded-lg px-4 py-4 mr-2 w-full"
                  />
                </div>
              </div>
            </div>

            {/* right section */}
            <div className="w-full md:max-w-sm max-w-64  md:mt-20 md:mx-8 mx-8 my-2">


            {/* <div className="md:flex md:items-center 3xl:mb-6 mb-3">
          <div className="md:w-1/3">
            <label
              htmlFor="Sub Type"
              className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
            >
              Sub-Type:
            </label>
          </div>
          <div className="md:w-2/3">
            <select
              id=""
              name="SubType"
              value={formData.SubType}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
            >
              <option disabled value="">Select</option>
              {expenseSubType.map((item) => (
                <option key={item.expenseSubTypeId} value={item.expenseSubTypeName}>
                  {item.expenseSubTypeName}
                </option>

              ))}
            </select>
          </div>
        </div> */}


              <div className="md:flex md:items-center mb-3 2xl:mb-6">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Expense Date"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Expense Date:
                  </label>
                </div>

                <div className="md:w-2/3">
                  <input
                    type="date"
                    placeholder="Expense Date"
                    name="ExpenseDate"
                    value={formData.ExpenseDate}
                    onChange={handleChange}
                    required
                    max={getCurrentDate()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                  />
                </div>
              </div>
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label
                    htmlFor="Attachment"
                    className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    Attachment:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="document"
                    type="file"
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}

                  />


                </div>
              </div>

              {isMisc && (
                <>
                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Expense Name"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Expense Name:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Expense Name"
                        name="ExpenseName"
                        value={formData.ExpenseName}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}

{isHotel && (
                <>


                  <div className="md:flex md:items-center mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="Hotel Location"
                        className="block text-gray-700 text-sm  font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        Hotel Location:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="Location"
                        name="Location"
                        value={formData.Location}
                        onChange={handleChange}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                  


              <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                <div className="md:w-1/3">
                  <label
                    htmlFor="To Date"
                    className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                  >
                    To Date:
                  </label>
                </div>
                <div className="md:w-2/3">
                  <input
                    type="date"
                    name="toDate"
                    value={formData.toDate}
                    onChange={handleChange}
                    required
                    max={getCurrentDate()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                  />
                </div>
              </div>

                </>

              )}

{isFuel && (
  <>
     

      <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
          <div className="md:w-1/3">
              <label
                  htmlFor="To Source"
                  className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
              >
                  ToSource :
              </label>
          </div>
          <div className="md:w-2/3">
              <input
                  type="text"
                  placeholder="Destination Place"
                  name="toSource"
                  value={formData.toSource}
                  onChange={handleChange}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
              />
          </div>
      </div>
      <div className="md:flex md:items-center 3xl:mb-6 mb-3">
          <div className="md:w-1/3">
              <label
                  htmlFor="Distance"
                  className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
              >
                  Distance:
              </label>
          </div>
          <div className="md:w-2/3">
              <input
                  type="tel"
                  placeholder="Distance in KMS."
                  name="distanceTravelled"
                  value={formData.distanceTravelled}
                  onChange={handleChange}
                  required
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
              />
          </div>
      </div>
  </>

)}

              {isFood && (
                <>
                  <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="No Of Person"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        No Of Person:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="tel"
                        placeholder="No Of Person."
                        name="NoOfPerson"
                        value={formData.NoOfPerson}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                </>

              )}

         





              {/* travel */}
              {isTravel && (
                <>
                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor=" From Source"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        From Source:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="FromPlace"
                        name="FromPlace"
                        value={formData.FromPlace}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="ToPlace"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        To Source :
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="text"
                        placeholder="To Place"
                        name="ToPlace"
                        value={formData.ToPlace}
                        onChange={handleChange}
                        required
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>





                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="From Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        From Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="FromDate"
                        value={formData.FromDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>

                  <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
                    <div className="md:w-1/3">
                      <label
                        htmlFor="To Date"
                        className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
                      >
                        To Date:
                      </label>
                    </div>
                    <div className="md:w-2/3">
                      <input
                        type="date"
                        name="ToDate"
                        value={formData.ToDate}
                        onChange={handleChange}
                        required
                        max={getCurrentDate()}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
                      />
                    </div>
                  </div>
                </>

              )}





            </div>


          </div>
          <div className="flex justify-center">
            <div className="my-2">
              <button
                onClick={handleSubmit}
                className="bg-[#000000] text-white px-4 py-2 rounded-lg mt-0 w-full"
              >
                Submit
              </button>
            </div>





            {/* Modal component */}
            <FormDetailsPopup formData={formData} showModal={showModal} setShowModal={setShowModal} handleSendData={handleSendData} />







          </div>
        </div>




        <TravelTempTable data={userTempVoucherData} />
        <div className="flex justify-center">
        <div className="my-2">
        <button className="bg-[#54BCE9] text-white px-4 py-2 rounded-xl mt-0 1/3"onClick={FinalSubmit}>Final Submit</button>


      </div>
      </div>
      </div>
    </div>

  )
}

export default TravelVoucherFill