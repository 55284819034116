import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const ExpenseForm = () => {
  const [formData, setFormData] = useState({
    ExpenseType: '',
    selectedExpenseSubTypes: [], // Updated to array
  });

  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expenseSubType, setExpenseSubType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [referenceUserId, setReferenceUserId] = useState(0);
  const initialAuthExpenseData = {expenseTypeId:  '',
                                  expenseTypeName : '',
                                  referenceUserId :  '',
                                  subTypeId: '',
                                  subTypeName: ''}

  const [authExpenseData, setAuthExpenseData] = useState(initialAuthExpenseData);

  useEffect(() => {
    const fetchExpenseData = async () => {
      setLoading(true);
      try {
        const expenseTypesResponse = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_expense_type');
        setExpenseTypes(expenseTypesResponse.data);
      } catch (error) {
        console.error('Error fetching expense types:', error);
        toast.error('Error fetching expense types');
      } finally {
        setLoading(false);
      }
    };

    fetchExpenseData();
  }, []);

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === 'ExpenseType') {
      setLoading(true);
      try {
        const expenseSubTypesResponse = await axios.get(`https://expenseapi.sarahtech.com/api/MasterApi/get_expense_sub_type?expenseTypeId=${value}`);
        setExpenseSubType(expenseSubTypesResponse.data);
      } catch (error) {
        console.error('Error fetching expense subtypes:', error);
        toast.error('Error fetching expense subtypes');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevFormData) => {
      let updatedSubTypes = [...prevFormData.selectedExpenseSubTypes];
      if (checked) {
        updatedSubTypes.push(parseInt(value, 10)); // Ensure value is stored as integer
      } else {
        updatedSubTypes = updatedSubTypes.filter((subType) => subType !== parseInt(value, 10));
      }
      console.log('Updated Subtypes:', updatedSubTypes); // Debugging line
      return {
        ...prevFormData,
        selectedExpenseSubTypes: updatedSubTypes, // Store as array
      };
    });
  };

   const handleSendAuthExpenseTempData = async (data) =>{
    try {
      console.log(data);
        const response = await axios.post('https://expenseapi.sarahtech.com/api/Accounts/employee_expense_type_auth_temp_post', data);
        console.log('API Response:', response.data);
        setReferenceUserId(response.data.refrence_id);
        console.log(referenceUserId);
        console.log(response.data.refrence_id);
      toast.success('Data saved successfully');
    } 
    catch (error) {
      console.error('Error saving data:', error);
      toast.error('Error saving data');
    }
    
   }

  const handleSave = async () => {
    const subTypeIds = formData.selectedExpenseSubTypes;
    const subTypeNames = subTypeIds.map((id) => {
      const subtype = expenseSubType.find((subType) => subType.expenseSubTypeId === id);
      return subtype ? subtype.expenseSubTypeName : 'Unknown SubType';
    });
  
    const expenseTypeId = parseInt(formData.ExpenseType, 10);
    const expenseTypeName = expenseTypes.find((type) => type.expenseId.toString() === formData.ExpenseType)?.ExpenseName || '';
  
     // Assuming initial userId is 0; you may adjust based on your logic
  
    try {
      // Loop through each selected subtype and make an API call
      for (let i = 0; i < subTypeIds.length; i++) {
        console.log(referenceUserId,i);
            const payload = {
              expenseTypeId,
              expenseTypeName,
              subTypeId: subTypeIds[i],
              subTypeName: subTypeNames[i],
              referenceUserId,
            };
            setAuthExpenseData({expenseTypeId:  expenseTypeId,
              expenseTypeName : expenseTypeName,
              referenceUserId :  referenceUserId,
              subTypeId: subTypeIds[i],
              subTypeName: subTypeNames[i]})

            console.log('Payload:', payload); // Debugging line
           handleSendAuthExpenseTempData(payload);  
      }
      toast.success('Data saved successfully');
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Error saving data');
    }
  };
  
  useEffect(()=>{
    console.log('refrence useeffect id', referenceUserId);
  },[referenceUserId]);
  
  return (
    <div className="p-6">
      <div className="md:flex md:items-center mb-4">
        <div className="md:w-1/3">
          <label
            htmlFor="ExpenseType"
            className="block text-gray-700 text-sm font-medium mb-1 md:text-right md:mb-0 pr-4"
          >
            Expense Type
          </label>
        </div>
        <div className="md:w-2/3">
          <select
            id="ExpenseType"
            name="ExpenseType"
            value={formData.ExpenseType}
            onChange={handleChange}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option disabled value="">Select</option>
            {expenseTypes.map((item) => (
              <option key={item.expenseId} value={item.expenseId}>
                {item.ExpenseName}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="border border-gray-300 rounded-md p-4 mb-4 h-52 overflow-y-auto">
        <div className="block text-gray-700 text-sm font-medium mb-2">Expense Sub Types</div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          {loading ? (
            <p>Loading options...</p>
          ) : expenseSubType.length === 0 ? (
            <p>No options available</p>
          ) : (
            expenseSubType.map((item) => (
              <div key={item.expenseSubTypeId} className="flex items-center">
                <input
                  type="checkbox"
                  id={item.expenseSubTypeId}
                  value={item.expenseSubTypeId}
                  checked={formData.selectedExpenseSubTypes.includes(item.expenseSubTypeId)}
                  onChange={handleCheckboxChange}
                  className="mr-2"
                />
                <label htmlFor={item.expenseSubTypeId} className="text-gray-700">{item.expenseSubTypeName}</label>
              </div>
            ))
          )}
        </div>
      </div>
      
      <div className="md:w-1/5">
        <button
          onClick={handleSave}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg w-full"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default ExpenseForm;
