import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import UserTable from "../component/UserTable";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';








const TravelExpUserPage = () => {
    const location = useLocation();
    const eventId = location.state?.eventId;

    // Use eventId in your component logic
    console.log('Event ID:', eventId);

  const [data, setData] = useState(false)
  const [refreshList, setRefresheList] = useState(0)
  const [eventIdVoucher, setEventIdVoucher] = useState(0);
  const [detailedData, setDetailedData] = useState(null);
  const [showVoucher, setShowVoucher] = useState(false);



  const navigate = useNavigate();


  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


  console.log(actionId);
  useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);




//   const fetchEventData = async () => {
//     debugger
//     try {
//       const userId = userData.userId;
//       const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_user_events_Pending/${userId}`);
      
//       if (!response.ok) {
//         throw new Error('Failed to fetch data');
//       }
      
//       const data = await response.json();
      
//       // Ensure data is an array and get the first event
//       const firstEvent = data.length > 0 ? data[0] : null;
      
//       if (firstEvent) {
//         // Extract and format the required fields
//         const eventName
//         = firstEvent.eventName
//         || 'No Name provided';
  
//         const task = firstEvent.task || 'No task provided';
//         const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
//         const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;
        
//         const trimmedData = {
//           task,
//           entryDate,
//           eventName,
//           expenseTypeId,
  
//         };
        
//         console.log('Event Data:', trimmedData);
//         setData(trimmedData);
//         return trimmedData;
//       } else {
//         // Handle case when there are no events
//         console.warn('No events found for the user.');
//         return {}; // Return empty object if no events found
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//       return {}; // Return empty object in case of error
//     }
//   };


  const fetchEventData = async () => {
    const url = `https://expenseapi.sarahtech.com/api/Events/get_event_details_by_event_id/${eventId}`;
    try {
      const response = await axios.get(url);
      console.log('evntid data', response);
  
      // Set detailed data at the beginning
      setDetailedData(response.data.data);
      setShowVoucher(true);
  
      // Ensure data is an array and get the first event
      const data = response.data.data;
      const firstEvent = data.length > 0 ? data[0] : null;
  
      if (firstEvent) {
        // Extract and format the required fields
        const eventName = firstEvent.eventName || 'No Name provided';
        const task = firstEvent.task || 'No task provided';
        const entryDate = firstEvent.entryDate ? firstEvent.entryDate.split("T")[0] : null;
        const expenseTypeId = firstEvent.expenseTypeId ? firstEvent.expenseTypeId : null;
        const fromDate = firstEvent.fromDate ? firstEvent.fromDate.split("T")[0] : null;
        const toDate = firstEvent.toDate ? firstEvent.toDate.split("T")[0] : null;
  
        const trimmedData = {
          task,
          entryDate,
          eventName,
          expenseTypeId,
          fromDate,
          toDate
        };
        console.log('Event Data:', trimmedData);
        setData(trimmedData);
        return trimmedData;
      } else {
        console.log('No event data found');
        return null;
      }
    } catch (err) {
      toast.error("Failed to Load Expense Voucher.");
    }
  };
  













  useEffect(() => {
    // fetchData();
    // fetchExpenseSubType();
  }, []);





  useEffect(() => {
    console.log('store data of User', userData);
    console.log('store data of User', isLoggedIn);
    fetchEventData();
  }, [refreshList]);


  useEffect(() => {
    console.log('Data:', data);
  }, [data]);



  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/TravelExpenseRequest" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2">{data.eventName
            ? data.eventName
            : "No task provided"}</p>
        </div>
        <div className="flex flex-row bg-white mx-2 py-4">
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            {/* Content on the left side */}
            <h1 className="text-xl font-bold mb-1 mx-2">Event Description:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.task ? data.task : "No task provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">From Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.fromDate ? data.fromDate : "No date provided"}</p>
          </div>
          <div className="flex w-1/3 mx-2 items-center justify-center"> {/* Updated line */}
            <h1 className="text-xl font-bold mx-2">To Date:</h1>
            <p className="text-xs text-slate-400 font-poppins mx-2">{data.toDate ? data.toDate : "No date provided"}</p>
          </div>
        </div>


{/* table */}
<div className="flex flex-col bg-white mx-2 my-2">
<div className="divide-gray-200 mx-2 w-full">
<UserTable data={detailedData}/>

      </div>
      </div>
      </div>
    </div>

  )
}

export default TravelExpUserPage