import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import Sidemenu from "./Sidemenu";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";


const TravelEventCreate = () => {
  const [userList, setUserList] = useState([]);
  const [travelEventTempId, setTravelEventTempId] = useState(null);
  const [travelEventTempUser, setTravelEventTempUser] = useState([]);
  const [isEventCreated, setIsEventCreated] = useState(false);
  const [refreshList, setRefresheList] = useState(0);
  const [formData, setFormData] = useState({
    eventName: "",
    task: "",
    fromDate: "",
    toDate: "",
    createdById: null,
    userId: [],
    expenseTypeId: "2",
  });
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth.user);

  const getTravelEventTempid = async () => {
    try {
      const url = "https://expenseapi.sarahtech.com/api/Events/new_journey_creation";
      const response = await axios.get(url);
      console.log("Travel Temp id", response.data);
      setTravelEventTempId(response.data.temp_journey_event_id);
      setIsEventCreated(true);
    } catch (error) {
      console.error("Error fetching voucher data:", error.message);
    }
  };

  const getTravelEventTempUser = async () => {
    try {
      const url = `https://expenseapi.sarahtech.com/api/Events/get_temp_authorized_user?temp_journey_event_reference_id=${travelEventTempId}`;
      const response = await axios.get(url);
      console.log("Travel Temp User", response.data);
      setTravelEventTempUser(response.data);
    } catch (error) {
      console.error("Error fetching voucher data:", error.message);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    e.target.style.height = "auto";
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleCheckboxChange = async (userId) => {
    setFormData((prevData) => {
      const newUserId = prevData.userId.includes(userId)
        ? prevData.userId.filter((id) => id !== userId)
        : [...prevData.userId, userId];
      return { ...prevData, userId: newUserId };
    });
    
    

    try {
      const response = await axios.post(
        "https://expenseapi.sarahtech.com/api/Events/eligible_users_for_travel",
        {
          tempReferenceEventId: travelEventTempId,
          eligibleUserId: userId,
          adminId: userData.userId,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      toast.success("User eligibility updated successfully");
      getTravelEventTempUser();
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(error.response.data);
      } else if (error.request) {
        toast.error("No response received from the server");
      } else {
        toast.error("An error occurred while processing your request");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = userData ? userData.userId : null;
    const updatedFormData = {
      ...formData,
      createdById: userId,
      tempEventId: travelEventTempId,
    };

    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();

      const response = await axios.post(
        "https://expenseapi.sarahtech.com/api/Events/journey_event_creation_with_authorized_user",
        {
          ...updatedFormData,
          ApplyDate: formattedDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Response:", response.data);
      toast.success(response.data.status);
      navigate("/DashboardAdmin");

      setFormData({
        eventName: "",
        task: "",
        fromDate: "",
        toDate: "",
        createdById: null,
        expenseTypeId: "2",
        tempEventId: "",
      });
      document.getElementById("document").value = "";
      setRefresheList(refreshList + 1);

  
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        toast.error(error.response.data);
      } else if (error.request) {
        toast.error("No response received from the server");
      }
    }
  };

  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        "https://expenseapi.sarahtech.com/api/Accounts/get_users_list"
      );
      setUserList(response.data);
      console.log("User List:", response.data);
    } catch (error) {
      console.error("Error fetching user list:", error);
      toast.error("Failed to fetch user list");
    }
  };

  const handleRemoveUser = async (eligibleUserId) => {
    debugger;
    try {
      await axios.delete(
        `https://expenseapi.sarahtech.com/api/Events/remove_user_from_journey`, {
          params: {
            userid: eligibleUserId,
            temp_journey_event_id: travelEventTempId,
          }
        }
      );
  
      toast.success("User removed successfully");
      getTravelEventTempUser([refreshList]);
    } catch (error) {
      console.error("Error removing user:", error);
      toast.error("Failed to remove user");
    }
  };
  

  useEffect(() => {
    fetchUserList();
    if (travelEventTempId) {
    }
  }, [travelEventTempId]);

  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/DashboardAdmin" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2">Create Event</p>
        </div>
        <div className="flex flex-col bg-white mx-2 my-2 items-start justify-start">
          {isEventCreated ? (
            <div className="flex flex-col md:flex-row w-full bg-white mx-2 my-2">
              <form
                onSubmit={handleSubmit}
                className="w-full md:mt-10 md:mx-4 mx-4 my-2"
                style={{ width: "40%" }}
              >
                <div className="md:flex md:items-center mb-2 w-full">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="expenseTypeId"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:mb-0 pr-4"
                    >
                      Expense Type:
                    </label>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <select
                      name="expenseTypeId"
                      value={formData.expenseTypeId}
                      onChange={handleChange}
                      required
                      className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    >
                      <option value="1">TRAVEL EXPENSE</option>
                      {/* Add more options as needed */}
                    </select>
                  </div>
                </div>
                <div className="md:flex md:items-center mb-2 w-full">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="eventName"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:mb-0 pr-4"
                    >
                      Event Name:
                    </label>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <input
                      type="text"
                      placeholder="Name"
                      name="eventName"
                      value={formData.eventName}
                      onChange={handleChange}
                      required
                      className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-2 w-full">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="task"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:mb-0 pr-4"
                    >
                      Task:
                    </label>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <input
                      type="text"
                      placeholder="Task"
                      name="task"
                      value={formData.task}
                      onChange={handleChange}
                      required
                      className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-2 w-full">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="fromDate"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:mb-0 pr-4"
                    >
                      From Date:
                    </label>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <input
                      type="date"
                      name="fromDate"
                      value={formData.fromDate}
                      onChange={handleChange}
                      required
                      className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    />
                  </div>
                </div>
                <div className="md:flex md:items-center mb-2 w-full">
                  <div className="md:w-1/2 w-full">
                    <label
                      htmlFor="toDate"
                      className="block text-gray-700 text-sm font-poppins mb-1 md:mb-0 pr-4"
                    >
                      To Date:
                    </label>
                  </div>
                  <div className="md:w-1/2 w-full">
                    <input
                      type="date"
                      name="toDate"
                      value={formData.toDate}
                      onChange={handleChange}
                      required
                      className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2"
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </form>
              <div className="md:mt-20 md:mx-8 mx-8 my-2 w-52">
                <h3 className="text-lg font-semibold">User List</h3>
                <ul className=" w-full h-52 overflow-y-scroll border rounded-md p-2">
                    {userList.map((user) => (
                      <li
                        key={user.userId}
                        className="flex items-center justify-between border-b border-gray-300 py-2"
                      >
                        <label className="flex items-center space-x-5 gap-2">
                         <input
                            type="checkbox"
                            name="userId"
                            value={user.userId}
                            checked={formData?.userId?.includes(user?.userId)}
                            onChange={() => handleCheckboxChange(user.userId)}
                            disabled={formData.userId.includes(user.userId)}
                            className="form-checkbox h-5 w-5 text-blue-600"
                          />
                          
                          {user.userName}

                          
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>

                {travelEventTempUser?.travelEventUsers?.length > 0 && (
  <div className="md:mt-20 md:mx-8 mx-8 my-2 min-h-52">
    <div className="border border-gray-300 rounded-md p-4 w-full ">
      <div className="block text-gray-700 text-sm font-poppins mb-1">Selected Users:</div>
      <div className="flex flex-wrap">
        {travelEventTempUser.travelEventUsers.map((user) => (
          <div
            key={user.eligibleUserId}
            className="flex items-center justify-between p-2 mx-4 mb-2"
            style={{ width: 'calc(100% / 4 - 16px)' }} // Adjust to control the column width
          >
            <span>{user.eligibleUserName}</span>
            <button
              onClick={() => handleRemoveUser(user.eligibleUserId)}
              className="text-red-500 hover:text-red-700"
            >
              <AiOutlineCloseCircle size={20} />
            </button>
          </div>
        ))}
      </div>
    </div>
  </div>
)}

            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full">
              <button
                onClick={getTravelEventTempid}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Create Travel Event
              </button>
            </div>
          )}


          
        </div>
        
     
      </div>
      
    </div>
  );
};

export default TravelEventCreate;
