// fileUtils.js

 const getFileType = (file) => {
    if (!file || !file.name) {
      return null; // or you can return an empty string ''
    }
  
    const filename = file.name;
    const lastDotIndex = filename.lastIndexOf('.');
    
    if (lastDotIndex === -1) {
      return null; // or you can return an empty string ''
    }
  
    return filename.slice(lastDotIndex + 1).toLowerCase();
  };
  
  export default getFileType;