// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/rootReducers';
import { saveState, loadState } from  '../localStorage';

const preloadedState = loadState(); // Load state from localStorage

const store = configureStore({
  reducer: rootReducer,
  preloadedState,
});

store.subscribe(() => {
  saveState(store.getState()); 
});

export default store;
