import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import applied from "../Images/applied.png";
import Accepted from "../Images/Accepted.png";
import rejected from "../Images/rejected.png";
import pending from "../Images/pending.png";
import Header from "../component/Header";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import Sidemenu from "../component/Sidemenu";
import axios from "axios";
import TravelTableAcc from "../component/TravelTableAcc";
import VoucherPopup from "../component/VoucherPopup";






const TravelExpenseRequestAccountant = () => {
   
    const [allTravelEvents, setAllTravelEvents] = useState([]);
    const [pageRefresh, setPageRefresh] = useState(0);
    const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  

  


  

    const getAllTravelEvents = async () => {
        try {
            const url = 'https://expenseapi.sarahtech.com/api/Accounts/get_travel_events_all_Accepted';
            const response = await axios.get(url);
            const travelEvents = response.data.data;
    
            // Filter events where eventStatus is 'Close'
            const closedTravelEvents = travelEvents.filter(event => event.eventStatus === 'Close');
    
            console.log('Closed Travel Events', closedTravelEvents);
            setAllTravelEvents(closedTravelEvents);
        } catch (error) {
            console.error('Error fetching voucher data:', error.message);
        }
    };
    
    

    
    useEffect(() => {
        getAllTravelEvents();

    }, [pageRefresh]);

 
  

   


    return (
        <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
        <a href="/DashboardAccount" className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <p className="text-gray-800 font-poppins ml-2">Expense Request</p>
        </div>


          


                {/* Table */}
                <div className="flex flex-col bg-white mx-2 my-2">
          <div className="divide-gray-200 mx-2 w-full">
            <TravelTableAcc data={allTravelEvents} showStatus={true} />

          </div>
        </div>


            </div>
        </div>
    );
}

export default TravelExpenseRequestAccountant;