import React, { useState, useImperativeHandle, forwardRef } from 'react';

const generateRandomString = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyz';
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    result += charset[randomIndex];
  }
  return result;
};

const Captcha = forwardRef((props, ref) => {
  const [captcha, setCaptcha] = useState(generateRandomString(6));
  const [userInput, setUserInput] = useState('');

  useImperativeHandle(ref, () => ({
    regenerateCaptcha() {
      setCaptcha(generateRandomString(6));
      setUserInput('');
    },
    verifyCaptcha() {
      return userInput === captcha;
    }
  }));

  const handleChange = (event) => {
    setUserInput(event.target.value);
  };

  return (
    <div>
      <p className="text-lg mb-2">Captcha: <strong className='bg-gray-100'>{captcha}</strong></p>
      <div className="flex items-center border-b border-gray-300">
        <input
           id="captcha"
           name="captcha"
           type="text"
           autoComplete="captcha"
           placeholder="ENTER CAPTCHA "
          value={userInput}
          onChange={handleChange}
          className="input-field focus:outline-none"

        />
        <button
          type="button"
          onClick={() => ref.current.regenerateCaptcha()}
          className="mb-4 p-2 mx-2 my-2 text-lg rounded-full text-gray hover:text-gray-800 focus:outline-none w-10 h-10 flex items-center justify-center"
        >
          &#x21bb;
        </button>
      </div>
    </div>
  );
});

export default Captcha;
