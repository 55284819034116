// Utility function to extract file name and file type from attachment path
const getFileNameAndType = (path) => {
    // Default values
    const defaultFileName = 'anonymous';
    const defaultFileType = 'png';

    // Check if the path is valid and not empty
    if (!path || typeof path !== 'string') {
        return { fileName: defaultFileName, fileType: defaultFileType };
    }

    // Split the path to get the file name with extension
    const fileNameWithExtension = path.split('\\').pop();

    // Check if the file name with extension is valid
    if (!fileNameWithExtension || !fileNameWithExtension.includes('.')) {
        return { fileName: defaultFileName, fileType: defaultFileType };
    }

    // Extract the file name and file type
    const fileName = fileNameWithExtension.split('.').slice(0, -1).join('.');
    const fileType = fileNameWithExtension.split('.').pop();

    return { fileName, fileType };
};

export default getFileNameAndType;
