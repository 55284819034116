import React, { useState } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import VoucherPopup from './VoucherPopup1';
import formatAmount from '../utilities/formatAmount';
import formatDate from '../utilities/formatDate';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, useTheme, Box } from '@mui/material';

const Table = ({ data }) => {
    const [showVoucher, setShowVoucher] = useState(false);
    const [voucherData, setVoucherData] = useState({
        voucherId: null,
        expenseType: "",
        subType: "",
        expenseDate: "2024-05-23T00:00:00",
        expenseAmount: null,
        attachment: null,
        attachmentPath: "",
        description: "",
        applyDate: "2024-05-03T00:00:00",
        fromPlace: "",
        toPlace: "",
        distance: 0,
        fromDate: "1900-01-01T00:00:00",
        toDate: "1900-01-01T00:00:00",
        userId: 0,
        userName: "",
        role: "",
        status: "",
        entryBy: "",
        donationType: "",
        donationFor: "",
        adminActionDate: "1900-01-01T00:00:00",
        adminRemark: "",
        accountantActionDate: "1900-01-01T00:00:00",
        accountantRemark: ""
    });

    const handleGetVoucherData = async (voucherId) => {
        debugger
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
        axios.get(url)
            .then((response) => {
                setVoucherData(response.data);
                setShowVoucher(true);
            })
            .catch((err) => {
                toast.error("Failed to Load Expense Voucher.");
            });
    }

    const TableData = Array.isArray(data) ? data.map((item, index) => ({
        ...item,
        sn: index + 1,
    })) : [];

    const columns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'center' }}>{value}</div>
            }
        },
        {
            name: "expenseSubType",
            label: "Expense Type",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatAmount(value)} &nbsp;₹</div>
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "entryDate",
            label: "Apply Date",
            options: {
                display: false,
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },

        {
            name: "userName",
            label: "Expense By",
            options: {
                display: true,

                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Accepted" && "text-yellow-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"} `} >{value}</p>
                ),
                filter: true,
                sort: true,
            }
        },
        {
            name: "voucherId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <button style={{ fontSize: 'inherit', fontFamily: 'inherit' }} className='capitalize px-5 py-1 inline-block text-start cursor-pointer text-blue-500' onClick={() => { handleGetVoucherData(value); setShowVoucher(!showVoucher) }}>View</button>
                )
            }
        }
    ];

    const options = {
        selectableRows: 'none',
        filterType: 'dropdown',
        elevation: 0,
        rowsPerPageOptions: [10, 15, 25, 50],
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto'
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });

    return (
        <>        
            {showVoucher && <VoucherPopup data={voucherData} openPopup={true} />}     
            <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                            title={"Expense Voucher List"}
                            data={TableData}
                            columns={columns}
                            options={options}
                        />
                    </ThemeProvider>
                </Box>
            </div>
        </>
    )
}

export default Table;
