
function formatAmount(number) {
    if (number >= 10000000) {
        return (number / 10000000).toFixed(2) + ' C';
    } else if (number >= 100000) {
        return (number / 100000).toFixed(2) + ' lakh';
    } else {
        return number.toString();
    }
}




export default formatAmount;


