import React, { useState } from 'react';
import { FaHome, FaRegListAlt } from 'react-icons/fa';
import { MdDashboard, MdOutlineCardTravel,MdAnalytics  } from "react-icons/md";
import { RiUserAddFill, RiFileCopy2Fill } from "react-icons/ri";
import { useSelector, useDispatch } from 'react-redux';
import { logout } from '../redux-toolkit/slice/authSlice';
import { useNavigate } from 'react-router-dom';
import Headerlogo from "../Images/Headerlogo.png";
import sidemenubg from "../Images/sidemenubg.png";
import sidemenuicon from "../Images/sidemenuicon.png";
import { CgProfile } from "react-icons/cg";
import { RiMenuAddFill } from "react-icons/ri";
import useIdleTimer from './useIdleTimer';

const Sidemenu = () => {
  const userData = useSelector(state => state.auth.user);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImageExpanded, setIsImageExpanded] = useState(false);

  const handleLogout = () => {
    sessionStorage.removeItem('authToken'); // Or any other authentication data
    navigate('/', { replace: true });
  };
  useIdleTimer(500000, handleLogout); // 5 minutes timeout


  const handleImageClick = () => {
    setIsImageExpanded(!isImageExpanded);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const extractFileName = (path) => {
    return path.split('\\').pop();
  };

  const profilePicFileName = userData?.profilePic ? extractFileName(userData.profilePic) : null;
  const profilePicUrl = profilePicFileName ? `https://expenseapi.sarahtech.com/api/Files/viewprofilepic/${encodeURIComponent(profilePicFileName)}` : sidemenuicon;

  const links = [];

  if (userData?.role === 'Admin') {
    links.push({ to: '/DashboardAdmin', label: 'Dashboard', icon: FaHome });
    // links.push({ to: '/EventCreate', label: 'Create Event', icon: FaRegListAlt });
    links.push({ to: '/AllAnalytics', label: 'All Analytics', icon: MdAnalytics });
    links.push({ to: '/ExpenseRequestAdmin', label: 'Request', icon: RiFileCopy2Fill });
    links.push({ to: '/Registration', label: 'Create User', icon: RiUserAddFill });
    links.push({ to: '/TravelExpenseRequest', label: 'Travel Request', icon: MdOutlineCardTravel });
    links.push({ to: '/UpdateProfileUser', label: ' Profile', icon: CgProfile });

  } else if (userData?.role === 'Accountant') {
    links.push({ to: '/DashboardAccount', label: 'Dashboard', icon: FaHome });
    links.push({ to: '/AllAnalytics', label: 'All Analytics', icon: MdAnalytics });
    links.push({ to: '/ExpenseRequestAccountant', label: 'Request', icon: RiFileCopy2Fill });
    links.push({ to: '/Registration', label: 'Create User', icon: RiUserAddFill });
    links.push({ to: '/TravelExpenseRequestAccountant', label: 'Travel Request', icon: MdOutlineCardTravel });
    links.push({ to: '/UpdateProfileUser', label: ' Profile', icon: CgProfile });

  } else if (userData?.role === 'User') {
    links.push({ to: '/Dashboard', label: 'Dashboard', icon: MdDashboard });
    links.push({ to: '/EventCreate', label: 'Create Event', icon: RiMenuAddFill });
    links.push({ to: '/UpdateProfileUser', label: ' Profile', icon: CgProfile });
  }

  return (
    <div className="bg-[#DBE8F1] min-h-screen sticky top-0 w-48 text-white shadow-lg rounded-lg">
      {/* Header */}
      <div className="flex items-center justify-between">
        <img
          src={Headerlogo}
          alt="Logo"
          className="h-8 mt-8 ml-8"
        />
      </div>

      {/* Sidebar Content */}
      <div className="mt-6 space-y-2 mx-1">
        {links.map(link => (
          <button
            key={link.to}
            className="flex justify-center items-center p-2 mt-2 rounded-2xl hover:bg-[#54BCE9] hover:text-white transition-colors duration-200 text-black"
            onClick={() => navigate(link.to)}
          >
            <link.icon size={20} className="mr-2" />
            <span className="text-md 3xl:text-lg mx-2">{link.label}</span>
          </button>
        ))}
      </div>

      {/* Sidebar Footer with Background Image */}
  
      <div className="absolute md:bottom-1 3xl:bottom-6
       w-full text-center p-2">
  <div
    className="p-4 rounded-lg bg-cover bg-center"
    style={{ 
      backgroundImage: `url(${sidemenubg})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      //height: isImageExpanded ? '14rem' : '12rem', // Adjust the height when image is expanded
    }}
  >
    {isLoggedIn && (
      <div className="bg-opacity-50 p-2 rounded-xl flex items-start justify-between space-x-2">
        <div className="relative w-14 h-14 flex-shrink-0">

          <img
            src={profilePicUrl}
            alt="User Icon"
            className={`rounded-full shadow-lg border-1 border-white cursor-pointer transition-transform duration-300 object-cover ${isImageExpanded ? 'scale-150' : 'w-14 h-14'}`}
            onClick={handleImageClick}
          />
        <p className="font-semibold text-white text-xs items-start">{userData?.role}</p>

        </div>

        <div className='flex flex-col mr-1'>
          <h1 className="font-semibold text-white text-base">{userData?.userName}</h1>
          {/* <p className="font-semibold text-rose-300">{userData?.company}</p> */}
        </div>
      </div>
    )}

    <button
      onClick={handleLogout}
      className="flex justify-center items-center p-2 mt-6 rounded-2xl bg-white hover:bg-blue-100 transition-colors duration-200 w-full text-black"
    >
      <span className="text-lg">Logout</span>
    </button>
  </div>
</div>



      {/* Modal */}
  
    </div>
  );
};

export default Sidemenu;
