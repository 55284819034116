import React, { useState, useEffect, useCallback } from "react";
import Header from "../component/Header";
import axios from "axios";
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import Sidemenu from "../component/Sidemenu";
import UserAnalytics from "../component/UserAnalytics";
import EventTable from "../component/EventTable";
import expensereq from "../Images/expensereq.png"
import usercreate from "../Images/usercreate.png"
import { getCurrentDate } from "../utilities/currentDateTime";
import { createJourneyEvent } from '../redux-toolkit/slice/eventSlice';




// import bill from '../Images/bill-book.png'
import {
  TERipple,
  TEModal,
  TEModalDialog,
  TEModalContent,
  TEModalHeader,
  TEModalBody,
  TEModalFooter,
} from "tw-elements-react";
import AdminAnalytics from "../component/AdminAnalytics";
import Table from "../component/Table";
import FormDetailsPopup from "../component/FormDetailsPopup";




const DashboardAdmin = () => {
  const [openEvents, setOpenEvents] = useState([]);
  const [closeEvents, setCloseEvents] = useState([]);
  const [refreshList, setRefreshList] = useState(0);
  const [userExpenseData, setUserExpenseData] = useState([]);
  const [userExpenseDataHistory, setUserExpenseDataHistory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);

  const [usersData, setUserData] = useState(null)
  const userData = useSelector(state => state.auth.user);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchUserEventDataPending = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_events_Pending/${userId}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setUserExpenseData(data);
      console.log('User Expense Data', data);
    } catch (err) {
      console.log('User Expense Data Error', err.response.data);
    }
  };

  const fetchUserEventDataHistory = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_events_history/${userId}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setUserExpenseDataHistory(data);
      console.log('User Expense Data History', data);
    } catch (err) {
      console.log('User Expense Data Error', err.response.data);
    }
  };


  const handleTravelEventClick = async () => {
    const resultAction = await dispatch(createJourneyEvent());
    if (createJourneyEvent.fulfilled.match(resultAction)) {
      navigate('/TravelEventCreate');
    }


  }


  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleDropdowns = () => {
    setIsOpens(!isOpens);
  };

  useEffect(() => {
    fetchUserEventDataPending();
    fetchUserEventDataHistory();
  }, [refreshList]);



  return (
    <div className="h-screen flex bg-[#F2FAFF]">
    <div className="p-2">
      <Sidemenu />
    </div>
    <div className="w-full flex flex-col overflow-y-auto">
      <div className="w-full h-12 flex items-center justify-start mt-12">
        <p className="text-gray-800 font-poppins ml-2">Dashboard</p>
      </div>
      {/* My Analytics */}
      <div className="flex flex-col md:flex-row mx-2 my-2">
        <div className="Col bg-white mx-2 my-2 md:w-3/4">
          <AdminAnalytics />
        </div>
        <div className="row w-3/4">
           <Link to="/Registration" className="flex bg-white justify-center items-center gap-10 3xl:gap-24 mx-2 my-2 h-40 3xl:h-60 p-2">
            <div className="flex flex-col justify-center items-center ml-2 mb-2 mx-2">
              <h1 className="text-xl 3xl:text-3xl font-bold mb-2">
                <h2 className="flex items-center space-x-2">
                  New User Creation
                </h2>
              </h1>
              <p className="text-xs">Add New User In Expense</p>
            </div>
            <div className="w-32 h-32 flex items-center justify-center mx-2">
              <img src={usercreate} alt="Images" className="w-32 h-32 py-3" />
            </div>
          </Link>
          <Link to="/ExpenseRequestAdmin" className="flex bg-white justify-center items-center mx-2 my-2 gap-10 3xl:gap-24 h-40 3xl:h-60 p-2">
            <div className="flex flex-col justify-center items-center ml-2 mb-2">
              <h1 className="text-xl 3xl:text-3xl font-bold mb-1">
                <p className="flex items-center space-x-1">
                  Expenses Request
                </p>
              </h1>
              <p className="text-xs">Expense request from users.</p>
            </div>
            <div className="flex items-center justify-center ml-2">
              <img src={expensereq} alt="Images" className="w-32 h-32 py-3" />
            </div>
          </Link>
        </div>
      </div>
      <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center relative">
        <div className="flex flex-row justify-between w-full px-4 py-4">
          <div className="flex-1">
            <h2 className="text-2xl font-bold">My Event</h2>
          </div>


          <div>
            <div className="relative mx-2">
            <Link
  to="/DraftVoucerDetailsFill"
  className="flex justify-center items-center p-2 rounded-2xl bg-[#54BCE9] hover:bg-[#3a9bc1] transition-colors duration-200 w-full text-white"
>
  <span className="mr-2">Create Draft</span>
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16m-7 6h7"
    ></path>
  </svg>
</Link>
{/* 
              {isOpens && (
                <div className="absolute mt-2 w-full bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li>
                      <Link to="/DraftVoucerDetailsFill" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                      Fill Draft
                      </Link>
                    </li>
                    <li>
                      <Link to="/AdminVoucerDetailsFill" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                        Get Draft
                      </Link>
                    </li>
                
                  </ul>
                </div>
              )} */}
            </div>
          </div>


          <div>
            <div className="relative">
            <button
  onClick={toggleDropdown}
  className="flex justify-center items-center p-2 rounded-2xl bg-[#54BCE9] hover:bg-[#3a9bc1] transition-colors duration-200 w-full text-white"
>
  <span className="mr-2">Create Expense</span>
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16m-7 6h7"
    ></path>
  </svg>
</button>

              {isOpen && (
                <div className="absolute mt-2 w-full bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li>
                      <Link to="/EventCreate" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                      Event Create
                      </Link>
                    </li>
                    <li>
                      <Link to="/AdminVoucerDetailsFill" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                        Expense Fill
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={handleTravelEventClick}
                        className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
                      >
                        Travel Event
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpen && <div className="absolute top-0 left-0 right-0 bottom-0 bg-transparent" onClick={toggleDropdown}></div>}

        {/* {isOpens && <div className="absolute top-0 left-0 right-0 bottom-0 bg-transparent" onClick={toggleDropdowns}></div>} */}


        
        <div className="divide-gray-200 mx-2 w-full">
        <EventTable data={userExpenseData} showStatus={false} />

        </div>
      </div>
      <div className="flex flex-col bg-white mx-2 my-2">
        <div className="divide-gray-200 mx-2 w-full">
          <h2 className="text-2xl font-bold mt-2">History</h2>
          <EventTable data={userExpenseDataHistory} showStatus={true} />

        </div>
      </div>
    </div>
  </div>


  )
}
export default DashboardAdmin