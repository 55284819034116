import React from 'react';
import { useLocation } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';

const TravelUserVoucherDetails = () => {
    const location = useLocation();
    const { userVoucherData } = location.state || {}; // Get the passed state

    const columns = [
        { name: 'column1', label: 'Column 1' },
        { name: 'column2', label: 'Column 2' },
        // Add more columns as per your data
    ];

    const options = {
        filterType: 'dropdown',
        responsive: 'standard',
    };
    console.log(userVoucherData);

    return (
        <div>
            <h2>Expense Voucher Details</h2>
            {userVoucherData ? (
                <MUIDataTable
                    title={'Voucher Details'}
                    data={userVoucherData}
                    columns={columns}
                    options={options}
                />
            ) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default TravelUserVoucherDetails;
