import { useEffect, useState } from 'react';

const useIdleTimer = (timeout = 300000, onTimeout) => {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    let timer;
    const resetTimer = () => {
      setIsIdle(false);
      clearTimeout(timer);
      timer = setTimeout(() => {
        setIsIdle(true);
        onTimeout();
      }, timeout);
    };

    const events = ['mousemove', 'keydown', 'click', 'scroll'];

    events.forEach(event => window.addEventListener(event, resetTimer));

    resetTimer();

    return () => {
      clearTimeout(timer);
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, [timeout, onTimeout]);

  return isIdle;
};

export default useIdleTimer;
