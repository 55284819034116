import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Profileicon from "../Images/Profileicon.png";
import applied from "../Images/iconapplied.png";
import Accepted from "../Images/iconaccepted.png";
import rejected from "../Images/iconrejected.png";
import pending from "../Images/iconpending.png";
import { PiUserSwitchThin } from "react-icons/pi";

const AdminAnalytics = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpens, setIsOpens] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState(null);
  const [employeeRoles, setEmployeeRoles] = useState([]);
  const [adminDetailsList, setAdminDetailsList] = useState([]);
  const [expenseData, setExpenseData] = useState({ totalApplied: 0, totalAmount: 0 });
  const [loading, setLoading] = useState(true);
  const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  const [userVoucherList, setUserVoucherList] = useState("");
  const userData = useSelector(state => state.auth.user);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const navigate = useNavigate();


  console.log('username', userData.userName)

  const fetchroles = async () => {
    try {
      const response = await fetch('https://expenseapi.sarahtech.com/api/MasterApi/get_roles');
      if (response.ok) {
        const jsonData = await response.json();
        setEmployeeRoles(jsonData.map(user => ({ roles: user.roles})));
      } else {
        console.error('Failed to fetch data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsernames = async (roles) => {
    
    try {
      const response = await fetch(`https://expenseapi.sarahtech.com/api/Accounts/get_users_list_by_role/${roles}`);
      if (response.ok) {
        const jsonData = await response.json();
        setAdminDetailsList(jsonData.map(user => ({ userName: user.userName, userId: user.userId })));
      } else {
        console.error('Failed to fetch data:', response.status);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const getVoucherListData = async (Status) => {
    try {
      const userId = userData.userId;
      const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details/${Status}/${userId}`;
      const response = await axios.get(url);
      console.log('Users status voucher List', response.data);
      setUserVoucherList(response.data);
    } catch (error) {
      console.error('Error fetching voucher data:', error.message);
    }
  };

  const handleGetUserAnalysis = async (userId) => {
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis_combined/${userId}`;
    try {
      const response = await axios.get(url);
      setUserVoucherAnalyticsData(response.data);
      console.log('User Analysis Data', response.data);
    } catch (error) {
      console.error('Error fetching user analysis data:', error);
      // Display a user-friendly error message or handle the error appropriately
    }
  };

  useEffect(() => {
    handleGetUserAnalysis();
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownSelect = (username, userId) => {
    setSelectedUser({ username, userId });
    handleGetUserAnalysis(userId);
    setIsOpen(false);
  };

  const toggleDropdowns = () => {
    setIsOpens(!isOpens);
  };

  const handleDropdownSelects = (roles) => {
    
    setSelectedRoles({ roles});
    fetchUsernames(roles);
    setIsOpens(false);
  };

  useEffect(() => {
    fetchroles();
    fetchUsernames();
    handleGetUserAnalysis(userData.userId)
  }, []);

  useEffect(() => {
    if (expenseData.totalAmount !== undefined && expenseData.totalApplied !== undefined) {
      console.log('Total Expenses Amount:', expenseData.totalAmount);
      console.log('Total Applied Expenses:', expenseData.totalApplied);
    }
  }, [expenseData]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleNavigate = (userId) => {
    navigate('/AdminVoucherDetailsAll', { state: { userId } });
  };
  const handleNavigates = (userId) => {
    navigate('/ApprovedAdminVoucherdetails', { state: { userId } });
  };

  return (
    <div className="px-2">








      
      <div className="flex my-5">
        <div className="w-2/3 p-2">
          <h1 className="flex text-xl 3xl:text-2xl font-bold mb-1">Expense Analytics</h1>
          <p className="text-xs 3xl:text-sm text-slate-400 font-poppins"> Your Expense Track Till Now.</p>
        </div>

  

        <div className="flex flex-col w-1/2 p-2 relative justify-end items-center mb-4">
      <div className="flex">
        <a className="flex items-center space-x-2 mx-2  ">
          {/* <img src={Profileicon} alt="Profile" className="w-12 h-16 py-3" /> */}
          <PiUserSwitchThin size={32} />
        </a>
        <button
          id="dropdownDefaultButton"
          onClick={toggleDropdowns}
          className="text-black font-medium rounded-lg text-sm px-1 py-2 text-center inline-flex items-center"
          type="button"
        >
          <h1>{selectedRoles ? selectedRoles.roles : 'Select Role'}</h1>
          <svg
            className={`w-2.5 h-2.5 ml-2 transform ${isOpens ? 'rotate-180' : ''}`}
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 10 6"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 4 4 4-4"
            />
          </svg>
        </button>
      </div>
      {isOpens && (
        <div className="absolute top-full left-0 mt-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownDefaultButton">
            {employeeRoles.map(user => (
              <li key={user.id} onClick={() => handleDropdownSelects(user.roles)}>
                <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {user.roles}
                </a>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>





    <div className="flex flex-col w-1/2 p-2 relative justify-end items-center">
  <div className="flex">
    <a className="flex items-center space-x-2 mx-2">
      <img src={Profileicon} alt="Profile" className="w-12 h-16 py-3" />
    </a>
    <button
      id="dropdownDefaultButton"
      onClick={toggleDropdown}
      className="text-black font-medium rounded-lg text-sm px-1 py-2 text-center inline-flex items-center"
      type="button"
    >
      <h1>{selectedUser ? selectedUser.username : userData.userName}</h1>
      <svg
        className={`w-2.5 h-2.5 ml-2 transform ${isOpen ? 'rotate-180' : ''}`}
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 6"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="m1 1 4 4 4-4"
        />
      </svg>
    </button>
  </div>

  {isOpen && (
    <div className="absolute top-full left-0 mt-0 bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700">
      <ul className="py-2 text-sm text-gray-700 dark:text-gray-200 max-h-60 overflow-y-auto" aria-labelledby="dropdownDefaultButton">
        {adminDetailsList.map(user => (
          <li key={user.userId} onClick={() => handleDropdownSelect(user.userName, user.userId)}>
            <a href="#" className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
              {user.userName}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )}
</div>

      </div>

      <div>
        <div className="flex flex-row justify-center items-start gap-28 3xl:gap-64 mx-4 3xl:mx-16 my-6 3xl:my-16">
          <div className="flex mb-2" onClick={() => handleNavigate(selectedUser?.userId || userData.userId)}>
            <div className="w-12 h-12 3xl:w-20 3xl:h-20 flex items-center justify-center my-2">
              <img src={applied} alt="Images" className="w-12 h-16 3xl:w-20 3xl:h-24 py-3" />
            </div>
            <div className="col-auto px-2">
              <h3 className="flex-grow font-poppins text-xs xl:text-xl 3xl:text-2xl">Applied Expenses</h3>
              <h1 className="font-bold my-1 inline-block cursor-pointer text-black text-xl">
                {userVoucherAnalyticsData.totalApplied || 0}
              </h1>
              <h3 className="flex-grow font-poppins text-xs xl:text-xl 3xl:text-2xl" style={{ color: '#BF83FF' }}>
                ₹{userVoucherAnalyticsData.totalAppliedAmount || 0}
              </h3>
            </div>
          </div>
          <div className="flex mb-2" onClick={() => handleNavigates(selectedUser?.userId || userData.userId)}>
            <div className="w-12 h-12 flex items-center justify-center my-2">
              <img src={Accepted} alt="Images" className="py-3" />
            </div>
            <div className="col-auto px-2">
              <h3 className="flex-grow text-xs xl:text-xl 3xl:text-2xl font-poppins" onClick={() => { getVoucherListData("Accepted") }}>Approved Expenses</h3>
              <h1 className="font-bold my-1 inline-block cursor-pointer text-black text-xl 3xl:text-2xl">
                {userVoucherAnalyticsData.totalApproved || 0}
              </h1>
              <h3 className="flex-grow font-poppins text-xs xl:text-xl 3xl:text-2xl" style={{ color: '#57AC65' }}>
                ₹{userVoucherAnalyticsData.totalApprovedAmount || 0}
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminAnalytics;
