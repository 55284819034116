import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
    actionId: null,
    status: 'idle',
    error: null,
};

// Thunk for creating a journey event
export const createJourneyEvent = createAsyncThunk(
  'eventAction/createJourneyEvent',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('https://expenseapi.sarahtech.com/api/Events/new_journey_creation');
      return response.data.temp_journey_event_id;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const eventSlice = createSlice({
    name: 'eventAction',
    initialState,
    reducers: {
        setActionIdSuccess: (state, action) => {
            state.actionId = action.payload;
        },
        removeActionIdSuccess: (state) => {
            state.actionId = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createJourneyEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createJourneyEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.actionId = action.payload;
            })
            .addCase(createJourneyEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setActionIdSuccess, removeActionIdSuccess } = eventSlice.actions;
export default eventSlice.reducer;
