// reducers.js
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../slice/authSlice'
import eventReducer from '../slice/eventSlice'
const rootReducer = combineReducers({
  auth: authReducer,
  event: eventReducer
  // Add other reducers here
});

export default rootReducer;
