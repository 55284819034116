import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { BiSolidLeftArrowAlt, BiSolidRightArrowAlt } from "react-icons/bi";
import Sidemenu from "../component/Sidemenu";

const ProfilePage = () => {
  const userData = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/UpdateProfileUser'); // Replace '/target-page' with your target route
  };

  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
         
        </div>
        <div className="flex flex-col md:flex-col bg-white mx-2 my-2">
          <div className="flex justify-center items-center my-2">
            <div className="md:w-1/5">
              <button 
                onClick={handleNavigate} 
                className="text-blue-800 hover:text-red-500 px-4 py-2 rounded-lg mt-0 w-full flex items-center justify-between"
              >
                <span>For Update Profile Click Here</span>
                <BiSolidRightArrowAlt />
              </button>
            </div>
          </div>
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-3xl font-poppins text-gray-900">Profile</h2>
          </div>
          <div className="flex flex-col md:flex-row justify-center">
            <div className="w-full md:max-w-sm max-w-64 md:mt-20 md:mx-8 mx-8 my-2">
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label htmlFor="profileName" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                    Name:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <p id="profileName" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    {userData.userName}
                  </p>
                </div>
              </div>
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label htmlFor="profileEmail" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                    Email:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <p id="profileEmail" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    {userData.email}
                  </p>
                </div>
              </div>
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label htmlFor="profileMobile" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                    Mobile:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <p id="profileMobile" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    {userData.mobileNo}
                  </p>
                </div>
              </div>
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label htmlFor="profileCompany" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                    Company:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <p id="profileCompany" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    {userData.company}
                  </p>
                </div>
              </div>
              <div className="md:flex md:items-center mb-3 2xl:mb-8">
                <div className="md:w-1/3">
                  <label htmlFor="profileRole" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                    Role:
                  </label>
                </div>
                <div className="md:w-2/3 flex">
                  <p id="profileRole" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    {userData.role}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
