import React, { useState, useEffect } from "react";
import applied from "../Images/iconapplied.png";
import Accepted from "../Images/iconaccepted.png";
import rejected from "../Images/iconrejected.png";
import pending from "../Images/iconpending.png";
import { useSelector } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";

const UserAnalytics = ({ key }) => {
  const [userVoucherAnalyticsData, setUserVoucherAnalyticsData] = useState("");
  const [userVoucherList, setUserVoucherList] = useState("");
  const userData = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  // const handleGetUserAmountAnalysis = async () => {
  //     const userId = userData.userId;
  //     const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_amount_analysis/${userId}`;

  //     try {
  //
  //         const response = await axios.get(url);
  //         setUserVoucherAmountAnalyticsData(response.data);
  //         console.log('User Analysis Data', response.data);
  //     } catch (err) {
  //         console.log('User Analysis Response Error', err.response.data);
  //     }
  // };

  const getVoucherListData = async (Status) => {
    try {
      const userId = userData.userId;
      const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details/${Status}/${userId}`;
      const response = await axios.get(url);
      console.log("Users status voucher List", response.data);
      setUserVoucherList(response.data);
    } catch (error) {
      console.error("Error fetching voucher data:", error.message);
    }
  };
  const handleGetUserAnalysis = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_expense_voucher_analysis_combined/${userId}`;

    try {
      const response = await axios.get(url);
      setUserVoucherAnalyticsData(response.data);
      console.log("User Analysis Data", response.data);
    } catch (err) {
      console.log("User Analysis Response Error", err.response.data);
    }
  };
  useEffect(() => {
    handleGetUserAnalysis();
  }, [key]);

  return (
    <div className="flex flex-row bg-white mx-2 py-4">
      <div className="w-1/3 mx-2 items-centre">
        {/* Content on the left side */}
        <h1 className="text-xl font-bold mb-1">Expense Analytics</h1>
        <p className="text-xs text-slate-400 font-poppins">
          {" "}
          Your Expense Track Till Now.
        </p>
      </div>
      <div className="w-52 md:w-3/4 2xl:w-1/2 px-12">
        <div className="flex flex-col md:flex-row justify-between items-center">
          {userVoucherAnalyticsData &&
            userVoucherAnalyticsData.status === "Successfully Get Data" && (
              <>
                <Link to="/AnalyticsVoucher" className="flex">
                  <div className="w-8 h-8 flex items-center justify-center my-2">
                    <img src={applied} alt="Images" />
                  </div>
                  <div className="col-auto px-2">
                    <h3 className="flex-grow font-poppins text-xs xl:text-xl">
                      Applied Expenses
                    </h3>
                    <h1 className="font-bold my-1 inline-block cursor-pointer text-black text-xl">
                      {userVoucherAnalyticsData.totalApplied || 0}
                    </h1>
                    <h3
                      className="flex-grow font-poppins text-xs xl:text-xl"
                      style={{ color: "#BF83FF" }}
                    >
                      ₹{userVoucherAnalyticsData.totalAppliedAmount || 0}
                    </h3>
                  </div>
                </Link>

                <Link to="/AcceptedVoucher" className="flex">
                  <div className="w-8 h-8 flex items-center justify-center my-2">
                    <img src={Accepted} alt="Images" className="py-3" />
                  </div>
                  <div className="col-auto px-2">
                    <h3
                      className="flex-grow text-xs xl:text-xl font-poppins"
                      onClick={() => {
                        getVoucherListData("Accepted");
                      }}
                    >
                      Accepted Expenses
                    </h3>
                    <h1 className="font-bold my-1 inline-block cursor-pointer text-black text-xl">
                    {userVoucherAnalyticsData.totalApproved + userVoucherAnalyticsData.totalAccepted || 0}

                    </h1>
                    <h3
                      className="flex-grow font-poppins text-xs xl:text-xl"
                      style={{ color: "#57AC65" }}
                    >
                      ₹{userVoucherAnalyticsData.totalApprovedAmount +userVoucherAnalyticsData.totalAcceptedAmount || 0}
                    </h3>
                  </div>
                  </Link>
                  <Link to="/PendingVoucher" className="flex">
                  <div className="w-8 h-8 flex items-center justify-center my-2">
                    <img src={pending} alt="Images" className="py-3" />
                  </div>
                  <div className="col-auto px-2 cursor-pointer">
                    <h1 className="flex-grow text-xs xl:text-md font-poppins">
                      Pending Expenses
                    </h1>
                    <h4 className="font-bold my-1 inline-block cursor-pointer text-black text-xl">
                      {userVoucherAnalyticsData.totalPending || 0}
                    </h4>
                    <h3
                      className="flex-grow font-poppins text-xs xl:text-xl"
                      style={{ color: "#ED7254" }}
                    >
                      ₹{userVoucherAnalyticsData.totalPendingAmount || 0}
                    </h3>
                  </div>
                  </Link>
                <Link to="/RejectedVoucher" className="flex">
                  <div className="w-8 h-8 flex items-center justify-center my-2">
                    <img src={rejected} alt="Images" className="py-3" />
                  </div>
                  <div className="col-auto px-2 cursor-pointer">
                    <h1 className="flex-grow text-xs xl:text-md font-poppins">
                      Rejected Expenses
                    </h1>
                    <h4 className="font-bold my-1 cursor-pointer text-black text-xl">
                      {userVoucherAnalyticsData.totalRejected || 0}
                    </h4>
                    <h3
                      className="flex-grow font-poppins text-xs xl:text-xl"
                      style={{ color: "#DC4868" }}
                    >
                      ₹{userVoucherAnalyticsData.totalRejectedAmount || 0}
                    </h3>
                  </div>
                  </Link>
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default UserAnalytics;
