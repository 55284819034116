import React, { useState, useEffect } from "react";
import Sidemenu from "../component/Sidemenu";
import axios from "axios";
import { toast } from "react-toastify";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from 'react-router-dom';
import { updateState } from '../redux-toolkit/slice/authSlice'
import sidemenuicon from "../Images/sidemenuicon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import edit from "../Images/edit.png"

const EditUserProfile = () => {

    const location = useLocation();
    const userId = location.state?.userId;
    console.log('userid', userId)

    const navigate = useNavigate();
    const dispatchevent = useDispatch();
    const userData = useSelector((state) => state.auth.user);
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    const [refreshList, setRefresheList] = useState(0);
    const [company, setCompany] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState([]);

    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordMatch, setPasswordMatch] = useState(true);
    const [isImageExpanded, setIsImageExpanded] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const hashedExistingPassword = CryptoJS.SHA256(userData.password).toString(CryptoJS.enc.Hex);
    console.log('userDetails', userDetails[0])
    const [fieldsEnabled, setFieldsEnabled] = useState({
        userName: false,
        email: false,
        mobileNo: false,
        companyId: false,
        role: false
    });

    const initialFormData = {
        userId: "",

        userName: "",
       
        email: "",
         role: "",
        mobileNo: "",
        companyId: ""


    };


    const [formData, setFormData] = useState(initialFormData);


    console.log('companyId', initialFormData.companyId)
    
  
    const fetchUserDetails = async () => {
     
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_users_list_by_userId/${userId}`;
    
        try {
            const response = await axios.get(url);
            const data = response.data[0];
          setUserDetails(data);
          setFormData(data);
          console.log('User UserDetails Data', data);
        } catch (err) {
          console.log('User Expense Data Error', err.response.data);
        }
      };

    const handleImageClick = () => {
        setIsImageExpanded(!isImageExpanded);
      };
      
    
    
      const extractFileName = (path) => {
        return path.split('\\').pop();
      };




    const profilePicFileName = userDetails?.profilePic ? extractFileName(userDetails.profilePic) : null;
    const profilePicUrl = profilePicFileName ? `https://expenseapi.sarahtech.com/api/Files/viewprofilepic/${encodeURIComponent(profilePicFileName)}` : sidemenuicon;

    const fetchCompany = async () => {
        try {
            const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
            setCompany(response.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching companies:', error);
            setLoading(false);
        }
    };


    const fetchRoles = async () => {
        try {
            const rolesResponse = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_roles');
            setRoles(rolesResponse.data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Roles:', error);
            setLoading(false);
        }
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };



    const handleSubmit = async () => {
        try {
            // Create a new object with only the fields you want to send
            const formDataToSend = {
                userId: formData.userId,
                userName: formData.userName,
                email: formData.email,
                role: formData.role,
                mobile: formData.mobileNo,
                companyId: formData.companyId
            };
    
            // Send the formDataToSend object instead of the entire formData
            const response = await axios.put(
                'https://expenseapi.sarahtech.com/api/NewUser/update_user_profile_by_Admin',
                formDataToSend,
                {
                    headers: {
                        'Content-Type': 'application/json', // Use JSON as the content type
                    },
                }
            );
                navigate('/UserDetails');
            
            toast.success(response.data.status);
            
            if (response.data.statusCode === 200) {
                dispatchevent(updateState(response?.data.response));
            }
    
            // Clear the form fields after successful submission
            setFormData(initialFormData);
            setRefresheList(refreshList + 1);
        } catch (error) {
            if (error.response) {
                toast.error(error.response.data);
            } else if (error.request) {
                toast.error('No response received from the server');
            } else {
                toast.error('An error occurred while processing your request');
            }
        }
    };
    

    useEffect(() => {
        fetchCompany();
        fetchRoles();
       
    }, []);
    useEffect(() => {
        fetchUserDetails();
    }, []);

    
    const getHref = () => {
        if (userData.role === 'Admin') {
            return '/UserDetails';
        } else if (userData.role === 'Accountant') {
            return '/UserDetails';
        }
        return '#'; // Default href if no role matches
    };

    if (loading) {
        return <div>Loading...</div>;
    }
    const handleEnableField = (field) => {
        setFieldsEnabled((prevState) => ({
            ...prevState,
            [field]: !prevState[field]
        }));
    };

    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                <a href={getHref()} className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <h6>Accounts Details</h6>

                </div>

                <div className="flex flex-row">
                <div className="Col bg-white mx-2 my-2 md:w-1/2">

                <div className="flex flex-col md:flex-col bg-white mx-2 my-2">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <h2 className="mt-6 text-center text-3xl font-poppins text-gray-900">   
                        <div className="bg-opacity-50 p-4 rounded-xl flex flex-col items-center space-y-4">
  {/* <h2 className="text-xl font-bold text-black">Profile Header</h2> */}
  <div className="bg-opacity-50 p-2 rounded-xl flex items-center justify-center space-x-10">
  <div className="w-24 h-24">
    <img
      src={profilePicUrl}
      alt="User Icon"
      className={`w-full h-full rounded-full shadow-lg border-1 border-white cursor-pointer transition-transform duration-300 object-cover`}
      onClick={handleImageClick}
    />
  </div>
  <div className="flex flex-col items-center">
    <p className="font-semibold text-black">{userDetails?.userName}</p>
    {/* <p className="font-semibold text-rose-300">{userData?.company}</p> */}
  </div>
  </div>
</div>

    </h2>
                    </div>
                    <div className="flex flex-col md:flex-row justify-center">
            <div className="w-full md:max-w-md max-w-64 md:mt-20 md:mx-8 mx-8 my-2">
                {/* Profile Name */}
                <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-2/3">
                        <label htmlFor="ProfileName" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                            Profile Name
                        </label>
                    </div>
                    <div className="md:w-full flex">
                        <input
                            id="userName"
                            name="userName"
                            type="text"
                            value={formData.userName}
                            onChange={handleChange}
                            disabled={!fieldsEnabled.userName}
                            className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.userName ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                        />
                        <button
                            type="button"
                            className="ml-2 p-1 bg-gray-300 rounded"
                            onClick={() => handleEnableField('userName')}
                        >
                            <img
          src={edit}
          alt="Logo"
          className="h-6"
        />
                        </button>
                    </div>
                </div>

                {/* Email */}
                <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-2/3 flex">
                        <label htmlFor="Email" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                            Email
                        </label>
                    </div>
                    <div className="md:w-full flex">
                        <input
                            id="email"
                            name="email"
                            type="text"
                            value={formData.email}
                            onChange={handleChange}
                            disabled={!fieldsEnabled.email}
                            className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.email ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                        />
                        <button
                            type="button"
                            className="ml-2 p-1 bg-gray-300 rounded"
                            onClick={() => handleEnableField('email')}
                        >
                            <img
          src={edit}
          alt="Logo"
          className="h-6"
        />
                        </button>
                    </div>
                </div>

                {/* Mobile */}
                <div className="md:flex md:items-center 3xl:mb-6 mb-3">
    <div className="md:w-2/3">
        <label htmlFor="mobile" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
            Mobile
        </label>
    </div>
    <div className="md:w-full flex">
        <input
            id="mobileNo"
            name="mobileNo"
            type="Number"
            value={formData.mobileNo}
            onChange={handleChange}
            disabled={!fieldsEnabled.mobileNo}
            className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.mobileNo ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
        />
        <button
            type="button"
            className="ml-2 p-1 text-gray-300 rounded"
            onClick={() => handleEnableField('mobileNo')}
        >
            <img
                src={edit}
                alt="Edit Icon"
                className="h-6"
            />
        </button>
    </div>
</div>



                {/* Company */}
                <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-2/3">
                        <label htmlFor="companyId" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                            Company
                        </label>
                    </div>
                    <div className="md:w-full flex">
                        <select
                            id="companyId"
                            name="companyId"
                            value={formData.companyId}
                            onChange={handleChange}
                            disabled={!fieldsEnabled.companyId}
                            className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.companyId ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                        >
                            {company.map((companyItem) => (
                                <option key={companyItem.companyId} value={companyItem.companyId}>
                                    {companyItem.companyName}
                                </option>
                            ))}
                        </select>
                        <button
                            type="button"
                            className="ml-2 p-1 bg-gray-300 rounded"
                            onClick={() => handleEnableField('companyId')}
                        >
                            <img
          src={edit}
          alt="Logo"
          className="h-6"
        />
                        </button>
                    </div>
                </div>

                {/* Role */}
                <div className="md:flex md:items-center 3xl:mb-6 mb-3">
                    <div className="md:w-2/3">
                        <label htmlFor="Role" className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4">
                            Role
                        </label>
                    </div>
                    <div className="md:w-full flex">
                        <select
                            id="role"
                            name="role"
                            value={formData.role}
                            onChange={handleChange}
                            disabled={!fieldsEnabled.role}
                            className={`bg-gray-50 border text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 ${fieldsEnabled.role ? 'text-sky-700 border-sky-700' : 'text-gray-500 border-gray-300'}`}
                        >
                            <option disabled value="">
                                Select
                            </option>
                            {roles.map((role) => (
                                <option key={role.id} value={role.roles}>
                                    {role.roles}
                                </option>
                            ))}
                        </select>
                        <button
                            type="button"
                            className="ml-2 p-1 bg-gray-300 rounded"
                            onClick={() => handleEnableField('role')}
                        >
                               <img
          src={edit}
          alt="Logo"
          className="h-6"
        />
                        </button>
                    </div>
                </div>

                {/* Submit Button */}
                <div className="flex justify-center items-center my-6">
                    <div className="md:w-full">
                        <button
                            onClick={handleSubmit}
                            className="bg-[#2A2A2A] text-white px-4 py-2 rounded-lg mt-0 w-full"
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
        </div>
                </div>
                </div>

           


                </div>
            
            </div>
        </div>

    )
}

export default EditUserProfile