import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table1";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';







const ApprovedAdminVoucherdetails = () => {


    const location = useLocation();
    const userId = location.state?.userId;
    console.log('USer ID:', userId);

    const [dataUserVoucher, setDataUserVoucher] = useState([]);
    const userData = useSelector(state => state.auth.user);


    const fetchDataUserVoucher = async () => {
   
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details/${'Approved'}/${userId}`;

        try {
            const response = await axios.get(url);
            const data = response.data;
            setDataUserVoucher(data);
            console.log('User Expense Data Voucher', data);
        } catch (err) {
            console.log('User Expense Data Error', err.response.data);
        }
    };








    useEffect(() => {
        fetchDataUserVoucher();
    }, []);







    const getHref = () => {
        if (userData.role === 'Admin') {
            return '/DashboardAdmin';
        } else if (userData.role === 'Accountant') {
            return '/DashboardAccount';
        }
        return '#'; // Default href if no role matches
    };









    return (
        <div className="h-screen flex bg-[#F2FAFF]">
            <div className="p-2">
                <Sidemenu />
            </div>
            <div className="w-full flex flex-col overflow-y-auto">
                <div className="w-full h-12 flex items-center justify-start mt-12">
                <a href={getHref()} className="flex items-center space-x-2">
                        <BiSolidLeftArrowAlt />
                    </a>
                    <h6>Accepted Voucher</h6>

                </div>

                <div className="flex flex-col bg-white mx-2 my-2">
                    <div className="divide-gray-200 mx-2 w-full">
                        <Table data={dataUserVoucher} />





                    </div>
                </div>
            </div>
        </div>

    )
}

export default ApprovedAdminVoucherdetails