import React, { useState } from 'react';
import { useSelector } from 'react-redux'; // Import useSelector from react-redux
import axios from "axios";
import { toast } from "react-toastify";
import Sidemenu from './Sidemenu';
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';


const EventCreate = () => {
  const [refreshList, setRefresheList] = useState(0)
  const [formData, setFormData] = useState({
    eventName: '',
    task: '',
    fromDate: '',
    toDate: '',
    createdById: null,
    userId: null,
    expenseTypeId: '',
    eventId: ''
   
  });
  const navigate = useNavigate();


  const userData = useSelector(state => state.auth.user);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));

    e.target.style.height = 'auto';
    e.target.style.height = `${e.target.scrollHeight}px`;
  };

  const handleSubmit = async (e) => {
    debugger
    e.preventDefault();
  
    // Set userId and createdById from userData
    const userId = userData ? userData.userId : null;
  
    const updatedFormData = {
      ...formData,
      createdById: userId,
      userId: userId,
    };
  
    try {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString();
  
      const response = await axios.post(
        'https://expenseapi.sarahtech.com/api/Accounts/post_event_creation',
        {
          ...updatedFormData,
          ApplyDate: formattedDate,
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      console.log('Response:', response.data);
      toast.success(response.data.status);
         // Navigate based on role
         if (userData.role === 'User') {
          navigate('/Dashboard');
        } else if (userData.role === 'Admin') {
          navigate('/DashboardAdmin');
        } else if (userData.role === 'Accountant') {
          navigate('/DashboardAccount');
        }
      setFormData({
        eventName: '',
        task: '',
        fromDate: '',
        toDate: '',
        createdById: null,
        userId: null,
        expenseTypeId: '',
        eventId: ''
      });
      document.getElementById('document').value = '';
      setRefresheList(refreshList + 1);



    } catch (error) {
      console.error('Error:', error);
      if (error.response) {
        toast.error(error.response.data);
      } else if (error.request) {
        toast.error('No response received from the server');
      } 
    }
};

const handleBackNavigation = () => {
  if (userData.role === 'User') {
    navigate('/Dashboard');
  } else if (userData.role === 'Admin') {
    navigate('/DashboardAdmin');
  } else if (userData.role === 'Accountant') {
    navigate('/DashboardAccount');
  }
}
  

  return (

    <div className="h-screen flex bg-[#F2FAFF]">
    <div className="p-2">
      <Sidemenu />
    </div>
    <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
        <a className="flex items-center space-x-2" onClick={handleBackNavigation}>
            <BiSolidLeftArrowAlt />
          </a>
          <p className="text-gray-800 font-poppins ml-2">Create Event</p>
        </div>
       
        <div className="flex flex-col bg-white mx-2 my-2 items-start justify-start">
  <div className="flex flex-col md:flex-row w-full md:max-w-sm max-w-64 2xl:gap-36 bg-white mx-2 my-2">
    <form onSubmit={handleSubmit} className='w-full md:mt-20 md:mx-8 mx-8 my-2'>
      <div className="md:flex md:items-center mb-3 2xl:mb-6 ">
        <div className="md:w-1/2">
          <label
            htmlFor="eventName"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
          >
            Event Name:
          </label>
        </div>
        <div className="md:w-3/4">
          <input
            type="text"
            placeholder="Name"
            name="eventName"
            value={formData.eventName}
            onChange={handleChange}
            required
            className="border border-gray-300 text-gray-900 text-xs rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2 "
          />
        </div>
      </div>
      <div className="md:flex md:items-center mb-6">
        <div className="md:w-1/2">
          <label
            htmlFor="task"
            className="block text-gray-700 text-sm font-poppins mb-1 md:text-justify md:mb-0 pr-4"
          >
            task:
          </label>
        </div>
        <div className="md:w-3/4">
        <textarea
      placeholder="task..."
      name="task"
      value={formData.task}
      onChange={handleChange}
      className="border border-gray-300 rounded-lg px-4 py-2 mr-2 w-full resize-none overflow-hidden"
      rows="1"
    />
        </div>
      </div>

      <button
        type="submit"
        className="w-full bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-md"
      >
        Submit
      </button>
    </form>
  </div>
</div>

      </div>
   
    </div>

  );
};

export default EventCreate;
