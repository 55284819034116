import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux-toolkit/store/store';
import "tw-elements-react/dist/css/tw-elements-react.min.css";
import { BrowserRouter } from 'react-router-dom';
// import '../src/style/datatables.css';
// import '../src/style/datatables.min.css';
// import '../src/script/datatables'
// import '../src/script/datatables.min'
// import '../src/script/PrintButton'
// import '../src/script/buttonsDataTable'
// import '../src/script/dataTable'
// import '../src/script/dataTablebuttons'
// import '../src/script/htmlfive'
// import '../src/script/jsZip'
// import '../src/script/pdfMake'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <BrowserRouter>
    <App />
    </BrowserRouter>
  </Provider>,
);

