import React, { useState } from 'react';

const ShowMoreText = ({ text, maxWords }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleIsExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const getTruncatedText = () => {
        if (typeof text !== 'string' || text.trim() === '') {
            return '';
        }
        const words = text.split(' ');
        if (words.length <= maxWords) {
            return text;
        }
        return words.slice(0, maxWords).join(' ') + '...';
    };

    const validatedText = typeof text === 'string' ? text : '';

    return (
        <div>
            <p className="font-bold">
                Description: &nbsp;
                <span className="font-normal">
                    {isExpanded ? validatedText : getTruncatedText()}
                </span>
            </p>
            {validatedText.split(' ').length > maxWords && (
                <button onClick={toggleIsExpanded} className="text-blue-500">
                    {isExpanded ? 'Show less' : 'Show more'}
                </button>
            )}
        </div>
    );
};

export default ShowMoreText;
