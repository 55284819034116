import React, { useState , useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import '../style/table.css';
import axios from 'axios';
import formatDate from '../utilities/formatDate';
import { getCurrentDate } from "../utilities/currentDateTime";

import { toast } from 'react-toastify';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VoucherPopup from './VoucherPopup1';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery, useTheme, Box, IconButton, Menu, MenuItem, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

const Table = ({ data }) => {
    
    const [showVouchers, setshowVouchers] = useState(false);
    const [showVoucher, setshowVoucher] = useState(false);
    const [userVoucherData, setUserVoucherData] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [paymentTypes, setPaymentTypes] = useState([]);
    const [company, setCompany] = useState([]);
    const [bank, setBank] = useState([]);
    const [remark, setRemark] = useState('');
    const [openRemarkDialog, setOpenRemarkDialog] = useState(false);
    const [formData, setFormData] = useState({
        CompanyId: '',
        BankId: '',
        PaymentType: '',
        paymentDate: ''
    });
    const [anchorUsersEl, setAnchorUsersEl] = useState(null);
    const [voucherData, setVoucherData] = useState({
        voucherId: null,
        expenseType: "",
        subType: "",
        expenseDate: "2024-05-23T00:00:00",
        expenseAmount: null,
        attachment: null,
        attachmentPath: "",
        description: "",
        applyDate: "2024-05-03T00:00:00",
        fromPlace: "",
        toPlace: "",
        distance: 0,
        fromDate: "1900-01-01T00:00:00",
        toDate: "1900-01-01T00:00:00",
        userId: 0,
        userName: "",
        role: "",
        status: "",
        entryBy: "",
        donationType: "",
        donationFor: "",
        adminActionDate: "1900-01-01T00:00:00",
        adminRemark: "",
        accountantActionDate: "1900-01-01T00:00:00",
        accountantRemark: ""
    });

    const [formErrors, setFormErrors] = useState({
        CompanyId: '',
        BankId: '',
        PaymentType: '',
        paymentDate: ''
    });
 
    const fetchCompany = async () => {
        try {
          const response = await axios.get('https://expenseapi.sarahtech.com/api/MasterApi/get_company_mater');
          setCompany(response.data);
        } catch (error) {
          console.error('Error fetching companies:', error);
        }
      };

    const handleApproveAll = async () => {

        const errors = {};
        if (!remark) errors.remark = 'remark is required.';
        if (!formData.CompanyId) errors.CompanyId = 'Company is required.';
        if (!formData.BankId) errors.BankId = 'Bank is required.';
        if (!formData.PaymentType) errors.PaymentType = 'Payment Type is required.';
        if (!formData.paymentDate) errors.paymentDate = 'Payment Date is required.';

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }
        try {
            const data = {
                eventId: userVoucherData[0].eventId,
                userId: userVoucherData[0].userId,
                remark: remark,
                companyId: formData.CompanyId,
                bankId: formData.BankId,
                paymentDate: formData.paymentDate,

                paymentType: formData.PaymentType
            };

            const response = await axios.put('https://expenseapi.sarahtech.com/api/Accounts/journey_all_approved_by_accountant', data);
            toast.success("All entries accepted successfully.");
            console.log('Response Handle Accept Expense Vocher:', response.data);
            setOpenRemarkDialog(false);
            setRemark('');
        } catch (error) {
            console.error('Error:', error);
            setRemark('');
        }
    };

      const handleChange = (e) => {
        console.log('handle change', e.target.value, e.target.name);
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
    
     

    
          if (name === 'CompanyId') {
    
         
    
              const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_bank_details_by_company_id/${value}`;
          
              axios.get(url)
                .then((response) => {
                  console.log("bank: ", response.data);
                  setBank(response.data);
                  // Uncomment the line below if you want to show a success message to the user
                  // toast.success("Expense subtypes loaded successfully.");
                })
                .catch((err) => {
                  console.error('Response Error: ', err.response ? err.response.data : err.message);
                  toast.error("Failed to load expense subtypes.");
                });
          }
    
    
          if (name === 'BankId') {
            debugger
    
         
    
            const url = `https://expenseapi.sarahtech.com/api/MasterApi/get_payment_details_by_bank_id/${value}`;
        
            axios.get(url)
              .then((response) => {
                console.log("Pay Type: ", response.data);
                setPaymentTypes(response.data);
                // Uncomment the line below if you want to show a success message to the user
                // toast.success("Expense subtypes loaded successfully.");
              })
              .catch((err) => {
                console.error('Response Error: ', err.response ? err.response.data : err.message);
                toast.error("Failed to load expense subtypes.");
              });
        }
      };


      useEffect(() => {
         fetchCompany();
      }, []);

    const handleMenuOpenUser = (event, userId) => {
        setAnchorUsersEl(event.currentTarget);
        setSelectedUserId(userId);
    };

    const handleMenuCloseUser = () => {
        setAnchorUsersEl(null);
        setSelectedUserId(null);
    };

    const handleGetVoucherData = async (voucherId) => {
        debugger
        const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details_by_voucher_id/${voucherId}`;
        axios.get(url)
            .then((response) => {
                setVoucherData(response.data);
                setshowVouchers(true);
            })
            .catch((err) => {
                toast.error("Failed to Load Expense Voucher.");
            });
    }
    const handleAcceptAll = async () => {

        try {
          
        const data = {
            eventId: userVoucherData[0].eventId,
            userId: userVoucherData[0].userId,
            adminRemark: remark
        };

          const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/journey_all_approved_by_admin', data);
          toast.success("All entries accepted successfully.");
          setshowVoucher(false);
          setOpenRemarkDialog(false);
    
          console.log('Response Handle Accept Expense Vocher:', response.data);
        
          setRemark('');
    
        } catch (error) {
    
          console.error('Error:', error);
          setRemark('');
        }
      }


const handleRejectAll = async () => {



    try {
      
    const data = {
        eventId: userVoucherData[0].eventId,
        userId: userVoucherData[0].userId,
        adminRemark: remark
    };

      const response = await axios.put('https://expenseapi.sarahtech.com/api/Admin/journey_all_rejected_by_admin', data);
      toast.success("All entries accepted successfully.");
      setshowVoucher(false);
      setOpenRemarkDialog(false);

      console.log('Response Handle Accept Expense Vocher:', response.data);
    
      setRemark('');

    } catch (error) {

      console.error('Error:', error);
      setRemark('');
    }
  }
    const handleData = async () => {
        debugger
        const url = `https://expenseapi.sarahtech.com/api/Events/get_travel_event_details_history/${selectedUserId}/${TableData[0]?.eventId}`;
        try {
            const response = await axios.get(url);
            setUserVoucherData(response.data.data); // Assuming response structure has a `data` property
            setshowVoucher(true);
        } catch (err) {
            toast.error("Failed to Load Expense Voucher.");
        }
    };


    const TableData = data?.map((item, index) => ({
        ...item,
        sn: index + 1,
    }));

console.log('table data',TableData )
const columns = [
    {
        name: "sn",
        label: "SN",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => <div style={{ textAlign: 'start' }}>{tableMeta.rowIndex + 1}</div>
        }
    },
    {
        name: "userName",
        label: "User Name",
        options: {
            customBodyRender: (value) => (
                <div style={{ textAlign: 'start' }}>{value}</div>
            )
        }
    },
    {
        name: "userAmount",
        label: "Amount Expense",
        options: {
            customBodyRender: (value) => (
                <div style={{ textAlign: 'start' }}>{value ? value : 'Not Applied'}</div>
            )
        },
    },
    {
        name: "userId",
        label: "Action",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value, tableMeta) => (
                <div style={{ textAlign: 'start' }}>
                    <IconButton onClick={(event) => handleMenuOpenUser(event, value, tableMeta.rowData[1])}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorUsersEl}
                        keepMounted
                        open={Boolean(anchorUsersEl) && selectedUserId === value}
                        onClose={handleMenuCloseUser}
                    >
                        <MenuItem onClick={() => handleData()}>Details</MenuItem>
                    </Menu>
                </div>
            )
        }
    }
];

    const detailedUserColumns = [
        {
            name: "sn",
            label: "SN",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value, tableMeta) => <div style={{ textAlign: 'start' }}>{tableMeta.rowIndex + 1}</div>
            }
        },
        {
            name: "expenseSubType",
            label: "Expense",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value}</div>
            }
        },
        {
            name: "expenseAmount",
            label: "Amount",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{value} ₹</div>
            }
        },
        {
            name: "expenseDate",
            label: "Expense Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "eventEntryDate",
            label: "Apply Date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (value) => <div style={{ textAlign: 'start' }}>{formatDate(value)}</div>
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                customBodyRender: (value) => (
                    <p className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${value === "Accepted" && "text-yellow-500"} ${value === "Approved" && "text-green-500"} ${value === "Pending" && "text-orange-500"} ${value === "Rejected" && "text-red-600"}`}>
                        {value}
                    </p>
                ),
                filter: true,
                sort: true,
            }
        },
        {
            name: "voucherId",
            label: "Action",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => (
                    <button style={{ fontSize: 'inherit', fontFamily: 'inherit' }} className='capitalize px-5 py-1 inline-block text-start cursor-pointer text-blue-500' onClick={() => { handleGetVoucherData(value); setshowVouchers(!showVouchers) }}>View</button>
                )
            }
        }
    ];
    


    const options = {
        selectableRows: 'none',
        elevation: 0,
        responsive: "standard",
        tableBodyHeight: 'auto',
        tableBodyMaxHeight: 'auto',
        pagination: false, 
        print: false, // Hide print option
        search: false, // Hide search option
        download: false, // Hide download option
        viewColumns: false, // Hide view columns option
        filter: false, // Hide filter option
        sort: false, // Disable sorting
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const getMuiTheme = () => createTheme({
        typography: {
            fontFamily: "Poppins",
        },
        palette: {
            background: {
                paper: "#ffffff",
                default: "#ffffff",
            },
            mode: 'light'
        },
        components: {
            MuiTableCell: {
                styleOverrides: {
                    head: {
                        backgroundColor: '#ffffff !important',
                        color: '#000000 !important', // Text color of the header
                        padding: "10px 4px",
                        borderBottom: "0px solid #ddd", // Horizontal divide
                        borderRight: "0px solid #ddd", // Vertical divide
                        textAlign: "start", // start align text
                        backgroundColor: '#ffffff !important', // Background color of the header
                        '& .MuiTableSortLabel-root': {
                            color: '#000000 !important',
                            '&.MuiTableSortLabel-active': {
                                color: '#000000 !important'
                            },
                        },
                        '& .MuiTableSortLabel-icon': {
                            color: '#000000 !important'
                        },
                        '&:hover': {
                            color: '#000000 !important', // Ensure text color is black on hover
                            backgroundColor: '#ffffff !important', // Ensure background color stays white on hover
                        },
                    },
                    body: {
                        padding: "7px 15px",
                        color: "#000000",
                        borderBottom: "2px solid #ECECEC", // Horizontal divide
                        borderRight: "0px solid #000000" // Vertical divide
                    },
                    footer: {
                        padding: "7px 15px"
                    }
                }
            }
        }
    });
    


    const handleOpenRemarkDialog = () => {
        setOpenRemarkDialog(true);
    };

    const handleCloseRemarkDialog = () => {
        setOpenRemarkDialog(false);
    };
    const CustomTitle = () => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
            <h1 className='text-bold'><strong>Travel Eligible List</strong></h1>
            <div ><strong>Total Expense Amount in Travel:</strong> {TableData && TableData[0] && TableData[0].totalAmount}</div>
        </div>
    );


    return (
        <>        
            <div className='py-5 min-h-screen flex justify-center items-center'>
                <Box width="100%">
                    <ThemeProvider theme={getMuiTheme()}>
                        <MUIDataTable
                             title={<CustomTitle />}
                            data={TableData}
                            columns={columns}
                            options={options}

                        />
                        

{showVoucher && (
                            <>
            {showVouchers && <VoucherPopup data={voucherData} openPopup={true} />}     

            <div>
                                    <MUIDataTable
                                        title={"User Voucher Details"}
                                        data={userVoucherData}
                                        columns={detailedUserColumns}
                                        options={options}
                                    />
                                    {userVoucherData.length > 0 && userVoucherData.every(item => item.status === "Accepted") && (
                                        <Box display="flex" justifyContent="center" mt={2}>
                                            <Button variant="contained" color="primary" onClick={handleOpenRemarkDialog}>Action</Button>
                                        </Box>
                                    )}
                                </div>

                                {/* Remark Dialog */}
                                <Dialog open={openRemarkDialog} onClose={handleCloseRemarkDialog}>
      <DialogTitle>Accountant Journey Approval</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            width: '500px', // Set the fixed width of the main box
            display: 'flex',
            flexDirection: 'column',
            gap: 2, // Apply gap between elements
          }}
        >
          <TextField
            autoFocus
            margin="dense"
            label="Remark"
            fullWidth
            value={remark}
            onChange={(e) => setRemark(e.target.value)}
            error={!!formErrors.remark}
            helperText={formErrors.remark}
          />
          <TextField
            select
            fullWidth
            label="Select Company"
            name="CompanyId"
            value={formData.CompanyId}
            onChange={handleChange}
            error={!!formErrors.CompanyId}
            helperText={formErrors.CompanyId}
          >
            {company.map((c) => (
              <MenuItem key={c.companyId} value={c.companyId}>
                {c.companyName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            label="Select Bank"
            name="BankId"
            value={formData.BankId}
            onChange={handleChange}
            disabled={!formData.CompanyId}
            error={!!formErrors.BankId}
            helperText={formErrors.BankId}
          >
            {bank.map((b) => (
              <MenuItem key={b.bankId} value={b.bankId}>
                {b.bankName}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            label="Select Payment Type"
            name="PaymentType"
            value={formData.PaymentType}
            onChange={handleChange}
            disabled={!formData.BankId}
            error={!!formErrors.PaymentType}
            helperText={formErrors.PaymentType}
          >
            {paymentTypes.map((pt) => (
              <MenuItem key={pt.paymentMasterId} value={pt.paymentType}>
                {pt.paymentType}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            type="date"
            placeholder="Payment Date"
             label="Select Date"
            name="paymentDate"
            value={formData.paymentDate}
            onChange={handleChange}
            error={!!formErrors.paymentDate}
            helperText={formErrors.paymentDate}
            required
            max={getCurrentDate()}
            fullWidth
            InputLabelProps={{ shrink: true }} // Ensures label is not hidden when value is present
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseRemarkDialog} color="primary">
          Cancel
        </Button>
        <Button onClick={handleApproveAll} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
                            </>
                        )}
                        
                    </ThemeProvider>
                </Box>
            </div>
        </>
    )
}

export default Table;
