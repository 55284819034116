import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import Sidemenu from "../component/Sidemenu";
import UserAnalytics from "../component/UserAnalytics";
import EventTable from "../component/EventTable";
import { Link } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { getCurrentDate } from "../utilities/currentDateTime";

const Dashboard = () => {
  const [openEvents, setOpenEvents] = useState([]);
  const [closeEvents, setCloseEvents] = useState([]);
  const [refreshList, setRefreshList] = useState(0);
  const [userExpenseData, setUserExpenseData] = useState([]);
  const [userExpenseDataHistory, setUserExpenseDataHistory] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const userData = useSelector((state) => state.auth.user);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const fetchUserEventDataPending = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_events_Pending/${userId}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setUserExpenseData(data);
      console.log('User Expense Data', data);
    } catch (err) {
      console.log('User Expense Data Error', err.response.data);
    }
  };


  

  const fetchUserEventDataHistory = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_user_events_history/${userId}`;

    try {
      const response = await axios.get(url);
      const data = response.data;
      setUserExpenseDataHistory(data);
      console.log('User Expense Data History', data);
    } catch (err) {
      console.log('User Expense Data Error', err.response.data);
    }
  };

  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchUserEventDataPending();
    fetchUserEventDataHistory();
  }, [refreshList]);



  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <p className="text-gray-800 font-poppins ml-2">Dashboard</p>
        </div>
        <UserAnalytics key={refreshList} />
        <div className="flex flex-col bg-white mx-2 my-2 items-center justify-center">
        <div className="flex flex-row justify-between w-full px-4 py-4">
          <div className="flex-1">
            <h2 className="text-2xl font-bold">My Event</h2>
          </div>
          <div>
            <div className="relative">
            <button
  onClick={toggleDropdown}
  className="flex justify-center items-center p-2 rounded-2xl bg-[#54BCE9] hover:bg-[#3a9bc1] transition-colors duration-200 w-full text-white"
>
  <span className="mr-2">Create Expense</span>
  <svg
    className="w-6 h-6"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 6h16M4 12h16m-7 6h7"
    ></path>
  </svg>
</button>

              {isOpen && (
                <div className="absolute mt-2 w-full bg-white rounded-md shadow-lg z-10">
                  <ul className="py-1">
                    <li>
                      <Link to="/EventCreate" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                      Event Create
                      </Link>
                    </li>
                    <li>
                      <Link to="/UserVoucherFill" className="block px-4 py-2 text-gray-800 hover:bg-gray-200">
                        Expense Fill
                      </Link>
                    </li>
                 
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {isOpen && <div className="absolute top-0 left-0 right-0 bottom-0 bg-transparent" onClick={toggleDropdown}></div>}
          <div className="divide-gray-200 mx-2 w-full">
            
            <EventTable data={userExpenseData} showStatus={false} />
          </div>
        </div>
        <div className="flex flex-col bg-white mx-2 my-2">
          <div className="divide-gray-200 mx-2 w-full">
            <h2 className="text-2xl font-bold mt-2">History</h2>
            <EventTable data={userExpenseDataHistory} showStatus={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
