import React, { useState } from "react";
import MUIDataTable from "mui-datatables";
import "../style/table.css";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  useMediaQuery,
  useTheme,
  Box,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";

const UserAccountTable = ({ data }) => {
  const [showVoucher, setShowVoucher] = useState(false);
  const [voucherData, setVoucherData] = useState({
    voucherId: null,
    expenseType: "",
    subType: "",
    expenseDate: "2024-05-23T00:00:00",
    expenseAmount: null,
    attachment: null,
    attachmentPath: "",
    description: "",
    applyDate: "2024-05-03T00:00:00",
    fromPlace: "",
    toPlace: "",
    distance: 0,
    fromDate: "1900-01-01T00:00:00",
    toDate: "1900-01-01T00:00:00",
    userId: 0,
    userName: "",
    role: "",
    status: "",
    entryBy: "",
    donationType: "",
    donationFor: "",
    adminActionDate: "1900-01-01T00:00:00",
    adminRemark: "",
    accountantActionDate: "1900-01-01T00:00:00",
    accountantRemark: "",
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const navigate = useNavigate();

  const handleMenuClick = (event, userId) => {
    setAnchorEl(event.currentTarget);
    setSelectedUserId(userId);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    // Implement your edit logic here
    handleCloseMenu();
  };

  const handleActivateDeactivate = async (userId) => {
    debugger;
    if (selectedUserId === null) {
      return;
    }

    const user = data.find((item) => item.userId === selectedUserId);
    
    if (!user) {
      return;
    }

    const newStatus = user.status === "Active" ? false : true;
    const requestBody = {
      userId: selectedUserId,
      userStatus: newStatus,
    };

    try {
      const response = await axios.put(
        "https://expenseapi.sarahtech.com/api/NewUser/updateUserLoginStatus",
        requestBody
      );
      if (response.status === 200) {
        toast.success(
          `User ${newStatus ? "activated" : "deactivated"} successfully.`
        );
        // Optionally, you can update the local state to reflect the change in the UI immediately
        user.status = newStatus ? "Active" : "Inactive";
      } else {
        toast.error("Failed to update user status.");
      }
    } catch (error) {
      toast.error("Failed to update user status.");
    }

    handleCloseMenu();
  };

  const TableData = Array.isArray(data)
    ? data.map((item, index) => ({
        ...item,
        sn: index + 1,
      }))
    : [];

  const columns = [
    {
      name: "sn",
      label: "SN",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "center" }}>{value}</div>
        ),
      },
    },
    {
      name: "userName",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "start" }}>{value}</div>
        ),
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "start" }}>{value}</div>
        ),
      },
    },
    {
      name: "mobileNo",
      label: "Mobile",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "start" }}>{value}</div>
        ),
      },
    },
    {
      name: "company",
      label: "Company",
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "start" }}>{value}</div>
        ),
      },
    },
    {
      name: "role",
      label: "Role",
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div style={{ textAlign: "start" }}>{value}</div>
        ),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRender: (value) => (
          <p
            className={`capitalize px-3 py-1 inline-block cursor-pointer text-start ${
              value === "Active" && "text-yellow-500"
            } ${value === "Inactive" && "text-red-600"} `}
          >
            {value}
          </p>
        ),
        filter: true,
        sort: true,
      },
    },
    {
        name: "userId",
        label: "Action",
        options: {
            filter: false,
            sort: true,
            customBodyRender: (value) => {
                // Find the user corresponding to the current userId (value)
                const user = data.find(item => item.userId === value);
    
                return (
                    <>
                        <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => handleMenuClick(event, value)}
                            style={{ fontSize: 'inherit', fontFamily: 'inherit' }}
                        >
                            ⋯
                        </Button>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleCloseMenu}
                        >
                            <MenuItem
                                fullWidth
                                variant="text"
                                onClick={() => {
                                    navigate('/EditUserProfile', { state: { userId: selectedUserId } });
                                    handleCloseMenu(); // Close the menu after navigation
                                }}
                                className="text-yellow-500"
                            >
                                Edit
                            </MenuItem>
                            <MenuItem onClick={() => { handleActivateDeactivate(value); }}>
                                {/* {user?.status !== "Active" ? "Activate" : "Change Status"} */}
                                Change Status
                            </MenuItem>
                        </Menu>
                    </>
                );
            }
        }
    }
    
    
  ];

  const options = {
    selectableRows: "none",
    filterType: "dropdown",
    elevation: 0,
    rowsPerPageOptions: [10, 15, 25, 50],
    responsive: "standard",
    tableBodyHeight: "auto",
    tableBodyMaxHeight: "auto",
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const getMuiTheme = () =>
    createTheme({
      typography: {
        fontFamily: "Poppins",
      },
      palette: {
        background: {
          paper: "#ffffff",
          default: "#ffffff",
        },
        mode: "light",
      },
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              backgroundColor: "#ffffff !important",
              color: "#000000 !important",
              padding: "10px 4px",
              borderBottom: "0px solid #ddd",
              borderRight: "0px solid #ddd",
              textAlign: "start",
              "& .MuiTableSortLabel-root": {
                color: "#000000 !important",
                "&.MuiTableSortLabel-active": {
                  color: "#000000 !important",
                },
              },
              "& .MuiTableSortLabel-icon": {
                color: "#000000 !important",
              },
              "&:hover": {
                color: "#000000 !important",
                backgroundColor: "#ffffff !important",
              },
            },
            body: {
              padding: "7px 15px",
              color: "#000000",
              borderBottom: "2px solid #ECECEC",
              borderRight: "0px solid #000000",
            },
            footer: {
              padding: "7px 15px",
            },
          },
        },
      },
    });

  return (
    <>
      <div className="py-5 min-h-screen flex justify-center items-center">
        <Box width="100%">
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title={"User Details"}
              data={TableData}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </Box>
      </div>
    </>
  );
};

export default UserAccountTable;
