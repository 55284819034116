import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import axios from "axios";
import { BiSolidLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Sidemenu from "../component/Sidemenu";
import Table from "../component/Table1";
import UserAnalytics from "../component/UserAnalytics";
import FormDetailsPopup from "../component/FormDetailsPopup";
import { getCurrentDate } from "../utilities/currentDateTime";
import { useSelector } from 'react-redux';






const RejectedVoucher = () => {


  const [dataUserVoucher, setDataUserVoucher] = useState([]);

  const [refreshList, setRefresheList] = useState(0)
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [selectedExpenseTypeId, setSelectedExpenseTypeId] = useState(0);
  const [eventIdVoucher, setEventIdVoucher] = useState(0);








  const userData = useSelector(state => state.auth.user);
  const actionId = useSelector((state) => state.event.actionId)
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);


  console.log(actionId);
  useEffect(() => { setEventIdVoucher(actionId) }, [eventIdVoucher]);


  const fetchDataUserVoucher = async () => {
    const userId = userData.userId;
    const url = `https://expenseapi.sarahtech.com/api/Accounts/get_voucher_details/${'Rejected'}/${userId}`;

    try {
        const response = await axios.get(url);
        const data = response.data;
        setDataUserVoucher(data);
        console.log('User Expense Data Voucher', data);
    } catch (err) {
        console.log('User Expense Data Error', err.response.data);
    }
};








  useEffect(() => {
    fetchDataUserVoucher();
  }, []);

















  return (
    <div className="h-screen flex bg-[#F2FAFF]">
      <div className="p-2">
        <Sidemenu />
      </div>
      <div className="w-full flex flex-col overflow-y-auto">
        <div className="w-full h-12 flex items-center justify-start mt-12">
          <a href="/Dashboard" className="flex items-center space-x-2">
            <BiSolidLeftArrowAlt />
          </a>
          <h6>Rejected Voucher</h6>
   
        </div>

        <div className="flex flex-col bg-white mx-2 my-2">
        <div className="divide-gray-200 mx-2 w-full">
        <Table data={dataUserVoucher} />





      </div>
      </div>
      </div>
    </div>

  )
}

export default RejectedVoucher